import "react-calendar-timeline/lib/Timeline.css";
import "./App.scss";
import { PrivateAppRoutes, PublicAppRoutes } from "./routes";
import { useAppContext } from "./contexts/AppContext";
import { useGoogleMapsApiLoader } from "./hooks/google-maps-api/useGoogleMapsApiLoader";
import { Navigate, Route, Routes, createSearchParams } from "react-router-dom";
import { constants_paths } from "./constants/path";
import { useState } from "react";
import { SHrPopup } from "@simplyhomes/react";
import { SoSRefreshModal } from "./components/SoSRefreshModal/SoSRefreshModal";
import { useSoSGetVersionFromRepo } from "./hooks/versions/useSoSGetVersionFromRepo";
import { useSoSVersion } from "./hooks/versions/useSoSVersion";

const App = () => {
   const { sosAccessToken } = useAppContext().appState;

   const [showRefreshModal, setShowRefreshModal] = useState(false);

   const qGetCurrentVersion = useSoSVersion();

   const qGetNewVersion = useSoSGetVersionFromRepo({
      onNewVersion: () => setShowRefreshModal(true),
   });

   const { isLoaded } = useGoogleMapsApiLoader();
   if (!isLoaded) return null;
   const curPath = window.location.pathname;
   const redirectSignInPath =
      curPath !== constants_paths.SIGN_IN
         ? constants_paths.SIGN_IN + "?" + createSearchParams({ redirect: curPath }).toString()
         : constants_paths.SIGN_IN;
   return (
      <div className="AppC">
         {sosAccessToken ? (
            <PrivateAppRoutes />
         ) : (
            <Routes>
               <Route path="*" element={<Navigate to={redirectSignInPath} replace />} />
            </Routes>
         )}
         <PublicAppRoutes />

         {qGetCurrentVersion.version &&
            qGetNewVersion.data?.version &&
            qGetCurrentVersion.version !== qGetNewVersion.data.version && (
               <SHrPopup show={showRefreshModal}>
                  <SoSRefreshModal onClose={() => setShowRefreshModal(false)} />
               </SHrPopup>
            )}
      </div>
   );
};

export default App;
