import { SHrSosButton, SHrSosTabsBar } from "@simplyhomes/react";
import classNames from "classnames/bind";
import { useState } from "react";
import styles from "./PViPropertyDetailsTabs.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { PVipropertyDetailsTab } from "./PVipropertyDetailsTab/PVipropertyDetailsTab";
import { PViUnitDetailsTab } from "./PViUnitDetailsTab/PViUnitDetailsTab";
import { PViPropertyDetailsTabOverview } from "../../PViPropertyDetailsTabOverview/PViPropertyDetailsTabOverview";
import { PViAreaTab } from "./PViAreaTab/PViAreaTab";
import { PViTaxHistory } from "./PViTaxHistory/PViTaxHistory";
import { PViUnderwriting } from "./PViUnderwriting/PViUnderwriting";
import { PViSource } from "./PViSource/PViSource";
import { useQPVi_Property } from "../../../../hooks/property-viability/queries/useQPVi_Property";
import { useMPVi_LockEdit } from "../../../../hooks/property-viability/mutates/properties/useMPVi_LockEdit";
import { SoSChangesHistoryModal } from "../../../SoSChangesHistoryModal/SoSChangesHistoryModal";
import { usePViContext } from "../../../../contexts/PViContext";
import { PViLead } from "./PViLead/PViLead";
import { useSearchQueryParams_PVi } from "../../../../hooks/react/useSearchQueryParams";

const cx = classNames.bind(styles);

const tabs = ["Overview", "Property Details", "Unit Details", "Area", "Tax History", "Underwriting", "Source", "Leads"];

export const PViPropertyDetailsTabs = () => {
   const { pviOverwrite } = usePViContext();

   const [pid] = useSearchQueryParams_PVi().pid;

   const [activeTab, setActiveTab] = useState("Overview");

   const qPVi_Property = useQPVi_Property();

   const { mutate: mutateLockEdit, isPending: isPendingLockEdit } = useMPVi_LockEdit();

   const property = qPVi_Property.data?.property;

   if (!property) return null;

   const { locked: lockedProperty } = property;

   return (
      <div className={cx("container")}>
         <div className={cx("leftC")}>
            <div className={cx("tabAndHistoryC")}>
               <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={setActiveTab} />
               <div className={cx("btnGroupC")}>
                  <SHrSosButton
                     type="text"
                     buttonProps={{
                        className: cx("btn", "viewHistory"),
                        onClick: () =>
                           pviOverwrite({
                              isShowChangesHistoryModal: true,
                              history: { table: "sos_properties", record_id: pid },
                           }),
                     }}
                  >
                     <img src={CF_CDN_URL("/assets/property_viability/history_info500.svg")} alt="" />
                     <span>View history</span>
                  </SHrSosButton>
                  <SoSChangesHistoryModal />

                  <SHrSosButton
                     type="text"
                     loading={isPendingLockEdit}
                     buttonProps={{
                        className: cx("btn", "unlock", { locked: lockedProperty }),
                        onClick: () => mutateLockEdit({ locked: !lockedProperty }),
                     }}
                  >
                     <div className={cx("img-icon", { locked: lockedProperty })}>
                        <img
                           src={CF_CDN_URL(
                              `/assets/property_viability/${
                                 lockedProperty ? "lock_negative500.svg" : "unlock_black.svg"
                              }`
                           )}
                           alt=""
                        />
                     </div>
                     Lock data
                  </SHrSosButton>

                  {/* <SHrSosButton type="positive" buttonProps={{ className: cx("btn") }}>
                     Save changes
                  </SHrSosButton> */}
               </div>
            </div>
            <div className={`${styles.pd}`}>
               {(activeTab === "Overview" && <PViPropertyDetailsTabOverview />) ||
                  (activeTab === "Property Details" && <PVipropertyDetailsTab />) ||
                  (activeTab === "Unit Details" && <PViUnitDetailsTab />) ||
                  (activeTab === "Area" && <PViAreaTab />) ||
                  (activeTab === "Tax History" && <PViTaxHistory />) ||
                  (activeTab === "Underwriting" && <PViUnderwriting pid={pid} />) ||
                  (activeTab === "Source" && <PViSource />) ||
                  (activeTab === "Leads" && <PViLead />)}
            </div>
         </div>
      </div>
   );
};
