import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import styles from "./PViPropertyDetails.module.scss";
import classNames from "classnames/bind";
import { PViAddress } from "./PViAddress/PViAddress";
import { PViPreviousNextProperty } from "./PViPreviousNextProperty/PViPreviousNextProperty";
import { PViMediaSlider } from "./PViMediaSlider/PViMediaSlider";
import { PViDataVerification } from "./PViMissingData/PViDataVerification";
import { useState } from "react";
import { PViRejectModal } from "./PViRejectModal/PViRejectModal";
import { SoSConversation } from "../../SoSConversation/SoSConversation";
import { PViPropertyDetailsTabs } from "./PViPropertyDetailsTabs/PViPropertyDetailsTabs";
import { PViPropertyDetailsTabOverview } from "../PViPropertyDetailsTabOverview/PViPropertyDetailsTabOverview";
import { ESoS_PropertyStatus } from "@simplyhomes/utils/dist/types/sos/properties/types";
import { useQPVi_PropertyStatuses } from "../../../hooks/property-viability/queries/useQPVi_PropertyStatuses";
import { usePViContext } from "../../../contexts/PViContext";
import { useQPVi_Property } from "../../../hooks/property-viability/queries/useQPVi_Property";
import { usePViDataVerification } from "../../../hooks/property-viability/hooks/usePViDataVerification";
import ReactJoyride, { Step } from "react-joyride";
import { useMPVi_QuickPickProperty } from "../../../hooks/property-viability/mutates/properties/useMPVi_QuickPickProperty";
import { useMPVi_UpdateProperty } from "../../../hooks/property-viability/mutates/properties/useMPVi_UpdateProperty";
import { SoSToolbox } from "../../../components/SoSToolBox/SoSToolBox";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { useMPVi_Submit_Property_To_AirTable } from "../../../hooks/property-viability/mutates/properties/useMPVi_Submit_Property_To_AirTable";
import { useSearchQueryParams_PVi } from "../../../hooks/react/useSearchQueryParams";
import { SoSSwitch } from "../../../components/common/SoSSwitch/SoSSwitch";

const cx = classNames.bind(styles);

export const PViPropertyDetails = () => {
   const { pviState, pviOverwrite } = usePViContext();

   const { isRunStepJoyRideInPropertyDetails, isViewCommented } = pviState;

   const [pid, setPid] = useSearchQueryParams_PVi().pid;

   const { isAllVerified } = usePViDataVerification();

   const qPVi_Property = useQPVi_Property();

   const mPVi_QuickPickProperty = useMPVi_QuickPickProperty();

   const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);

   const mPVi_UpdateProperty = useMPVi_UpdateProperty();

   const qPVi_PropertyStatuses = useQPVi_PropertyStatuses();

   const { mutate: mutateSubmitIntoAT, isPending: isPendingSubmitIntoAT } = useMPVi_Submit_Property_To_AirTable();

   const newPropertiesCount = qPVi_PropertyStatuses.data?.statusMap?.New ?? 0;

   const loadingMsg = qPVi_Property.isLoading
      ? "Loading Property..."
      : mPVi_QuickPickProperty.isMutating
      ? "Loading Next Property..."
      : null;

   if (loadingMsg)
      return (
         <article className={`${styles.contentC} ${styles.loading}`}>
            <SHrSpinner />
            <span>{loadingMsg}</span>
         </article>
      );

   if (qPVi_Property.isError)
      return (
         <article className={`${styles.contentC} ${styles.loading}`}>
            <span>Failed to load property</span>
         </article>
      );

   const handleReviewLater = () =>
      mPVi_UpdateProperty.mutate({ status: ESoS_PropertyStatus.UNVERIFIED, getNextQuickPick: true });

   const handleReviewNow = () =>
      mPVi_UpdateProperty.mutate({ status: ESoS_PropertyStatus.UNVERIFIED, getNextQuickPick: false });

   const handleMarkVerified = () =>
      mPVi_UpdateProperty.mutate({ status: ESoS_PropertyStatus.VERIFIED, getNextQuickPick: false });

   const handleValidateProperty = () =>
      mPVi_UpdateProperty.mutate({ status: ESoS_PropertyStatus.VIABLE, getNextQuickPick: false });

   const handleClose = () => {
      pviOverwrite({ excludedPropertyIds: [] });
      setPid("");
   };

   if (!qPVi_Property.data?.property)
      return (
         <article className={`${styles.contentC}`}>
            <div className={cx("no-prop")}>
               <div className={cx("close-btn")}>
                  <button type="button" title="close" onClick={handleClose}>
                     <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                  </button>
               </div>
               <span>No property to review</span>
            </div>
         </article>
      );
   const { status } = qPVi_Property.data.property;
   const { airtable_existing } = qPVi_Property.data;

   const isNewStatus = status === ESoS_PropertyStatus.NEW;

   const handleViewPropertyAT = () => {
      window.open(
         `https://airtable.com/apptx6ZpUz4a3xAwX/tblJ9n4zVNc6Zm0Lt/viwxHl046w8sigMMI/${airtable_existing}`,
         "_blank"
      );
   };

   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.toolBoxWrapper}`}>
            <SoSToolbox
               items={[
                  {
                     title: "Guide For Property",
                     icon: "info",
                     onClick: () => pviOverwrite({ isRunStepJoyRideInPropertyDetails: true }),
                  },
               ]}
            />
         </div>
         <ReactJoyride
            callback={(data) => {
               if (data.action === "skip" || data.status === "finished")
                  pviOverwrite({ isRunStepJoyRideInPropertyDetails: false });
            }}
            continuous
            run={isRunStepJoyRideInPropertyDetails}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={joyrideSteps}
         />
         <SHrPopup show={isOpenRejectModal} type="center">
            <PViRejectModal toggle={() => setIsOpenRejectModal(false)} />
         </SHrPopup>
         <div className={`${styles.topC}`}>
            <div className={`${styles.top1}`}>
               <div>
                  {isNewStatus ? (
                     <span className={cx("new", "_jr_pviQueue")}>
                        New Properties Quick Pick ({newPropertiesCount - 1 >= 0 ? newPropertiesCount - 1 : 0} left) with{" "}
                        {status}
                     </span>
                  ) : (
                     <PViAddress />
                  )}
                  <div className={cx("close-btn-responsive")}>
                     <button type="button" title="close" onClick={handleClose}>
                        <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                     </button>
                  </div>
               </div>

               <div className={`${styles.top1Right}`}>
                  {!isNewStatus && (
                     <div className={cx("statusAndBtnC")}>
                        <div className={cx("statusC", "_jr_pviStatus")}>
                           <span>Status</span>
                           <span className={cx("status")}>{status}</span>
                        </div>
                        <div className={cx("divider-container")}>
                           <hr className={cx("divider")} />
                        </div>

                        {airtable_existing ? (
                           <SHrSosButton
                              loading={false}
                              type={"text"}
                              buttonProps={{
                                 className: cx("airtable", "existed"),
                                 onClick: handleViewPropertyAT,
                              }}
                           >
                              <div className={cx("img-icon")}>
                                 <img src={CF_CDN_URL(`/assets/open_in_new_black.svg`)} alt="" />
                              </div>
                              See on Airtable
                           </SHrSosButton>
                        ) : (
                           <SoSConfirmWrapper
                              type="default"
                              title="Port property to AirTable"
                              content=" This will create a new property/lead on Airtable!"
                              onConfirm={() => mutateSubmitIntoAT({ pid })}
                           >
                              <SHrSosButton
                                 loading={isPendingSubmitIntoAT}
                                 type={"elevated"}
                                 buttonProps={{
                                    className: cx("airtable"),
                                 }}
                              >
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL(`/assets/Airtable.svg`)} alt="" />
                                 </div>
                                 Add to Airtable
                              </SHrSosButton>
                           </SoSConfirmWrapper>
                        )}

                        <div className={cx("divider-container")}>
                           <hr className={cx("divider")} />
                        </div>

                        <div className={cx("btnC", "_jr_pviStatusBtns")}>
                           <span>Mark as</span>
                           {status !== "Rejected" && (
                              <SHrSosButton
                                 loading={mPVi_UpdateProperty.isPending}
                                 buttonProps={{
                                    className: cx("btn", "reject"),
                                    onClick: () => setIsOpenRejectModal(true),
                                 }}
                              >
                                 Rejected
                              </SHrSosButton>
                           )}
                           {(status === "Rejected" || status === "Unverified") && (
                              <SHrSosButton
                                 loading={mPVi_UpdateProperty.isPending}
                                 buttonProps={{
                                    disabled: !isAllVerified,
                                    className: cx("btn", "mark-as-verified"),
                                    title: !isAllVerified ? "Please verify all data first" : "Verify",
                                    onClick: handleMarkVerified,
                                 }}
                              >
                                 Verified
                              </SHrSosButton>
                           )}
                           {status === "Verified" && (
                              <SHrSosButton
                                 loading={mPVi_UpdateProperty.isPending}
                                 buttonProps={{
                                    className: cx("btn", "validate"),
                                    onClick: handleValidateProperty,
                                 }}
                              >
                                 Viable
                              </SHrSosButton>
                           )}
                        </div>
                     </div>
                  )}
                  <div className={cx("close-btn")}>
                     <button type="button" title="close" onClick={handleClose}>
                        <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                     </button>
                  </div>

                  {!isNewStatus && (
                     <div className={cx("toggleCommentC")}>
                        <SoSSwitch
                           label="View Comments"
                           checked={isViewCommented}
                           onChange={(b) => {
                              pviOverwrite({ isViewCommented: b });
                           }}
                        />
                     </div>
                  )}
               </div>
            </div>
            {isNewStatus && (
               <div className={`${styles.top2}`}>
                  <PViAddress />
                  <PViPreviousNextProperty />
               </div>
            )}
         </div>
         <div className={`${styles.bodyC}`}>
            <div className={cx("topMapC")}>
               <PViMediaSlider />
               {(status === "New" || status === "Unverified" || status === "Rejected") && <PViDataVerification />}
            </div>

            <div className={cx("detailC")}>
               {isNewStatus ? <PViPropertyDetailsTabOverview /> : <PViPropertyDetailsTabs />}
               <div className={cx("conversationC", { hidden: !isViewCommented })}>
                  <SoSConversation />
               </div>
            </div>
         </div>

         {isNewStatus && (
            <div className={cx("controlsC", "_jr_pviControls")}>
               <SHrSosButton
                  loading={mPVi_UpdateProperty.isPending}
                  buttonProps={{ className: cx("btn", "reject"), onClick: () => setIsOpenRejectModal(true) }}
               >
                  Reject
               </SHrSosButton>
               <SHrSosButton
                  loading={mPVi_UpdateProperty.isPending}
                  buttonProps={{ className: cx("btn", "review-later"), onClick: handleReviewLater }}
               >
                  Review Later
               </SHrSosButton>
               <SHrSosButton
                  loading={mPVi_UpdateProperty.isPending}
                  buttonProps={{ className: cx("btn", "review-now"), onClick: handleReviewNow }}
               >
                  Review Now
               </SHrSosButton>
            </div>
         )}
      </article>
   );
};

const joyrideSteps: Step[] = [
   {
      target: "._jr_pviAddress",
      content: "This is the address of the currently viewed property",
      disableBeacon: true,
   },
   {
      target: "._jr_pviAddressCopy",
      content: "You can click here to copy the address",
   },
   {
      target: "._jr_pviQueue",
      content: "This indicates the number of properties left in the quick pick queue",
   },
   {
      target: "._jr_pviStatus",
      content: "This is the current status of the property",
   },
   {
      target: "._jr_pviStatusBtns",
      content: "You can change the status of the property here",
   },
   {
      target: "._jr_pviQueueNavigation",
      content: "You can navigate between properties in the quick pick queue",
   },
   {
      target: "._jr_pviMediaSlider",
      content: "This is the media slider. You can view map, property images and videos here",
   },
   {
      target: "._jr_pviDataVerification",
      content: "This is the data verification section. You can verify the data here",
   },
   {
      target: "._jr_pviPropertyDetails",
      content: "This is the property details section. You can view all details related to the property here",
   },
   {
      target: "._jr_pviConversation",
      content: "This is the conversation section. You can view and add threads/commends here",
   },
   {
      target: "._jr_pviControls",
      content: "These are the controls for new properties. You can reject, review later or review now here",
   },
];
