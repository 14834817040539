import classNames from "classnames/bind";
import styles from "./TransactionScheduleInspectionModal.module.scss";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { SoSInputTitle } from "../../../components/common/SoSInputTitle/SoSInputTitle";
import { useQ_Users } from "../../../hooks/queries/user/useQ_Users";
import { useState } from "react";
import {
   Transactions_ScheduleInspection_Body,
   useMTrans_ScheduleInspection,
} from "../../../hooks/transactions/mutates/useMTrans_ScheduleInspection";

const cx = classNames.bind(styles);

export const TransactionScheduleInspectionModal = ({ onClose }: TransactionScheduleInspectionModalProps) => {
   const [scheduleInspectionBody, setScheduleInspectionBody] = useState<Transactions_ScheduleInspection_Body>({
      inspection_date: "",
      reno_person: "",
      assigned_poc: "",
   });

   const qUser = useQ_Users();

   const { mutate: mutateScheduleInspection, isPending: isPendingScheduleInspection } = useMTrans_ScheduleInspection();

   const users = qUser.data?.users || [];

   const userOptions = users.map((user) => ({
      label: user.name,
      value: user.uid,
   }));

   const handleScheduleInspection = () => {
      mutateScheduleInspection(scheduleInspectionBody);
      onClose();
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/transaction/event_black.svg")} alt="" />
            </div>
            <span>Schedule an inspection</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("bodyC")}>
            <div className={cx("inputSelect")}>
               <SoSInputSelect
                  title="Assign to a reno person"
                  options={userOptions}
                  value={userOptions?.find((v) => v.value === scheduleInspectionBody.reno_person)?.label || ""}
                  isMulti={false}
                  onChange={(o) => setScheduleInspectionBody({ ...scheduleInspectionBody, reno_person: o.value })}
               />
            </div>

            <div className={cx("inputSelect")}>
               <SoSInputSelect
                  title="Assign a POC"
                  options={userOptions}
                  value={userOptions?.find((v) => v.value === scheduleInspectionBody.assigned_poc)?.label || ""}
                  isMulti={false}
                  onChange={(o) => setScheduleInspectionBody({ ...scheduleInspectionBody, assigned_poc: o.value })}
               />
            </div>

            <div className={cx("input_search")}>
               <SoSInputTitle
                  value={scheduleInspectionBody.inspection_date}
                  title="Choose a date"
                  type="date"
                  handleValue={(value) =>
                     setScheduleInspectionBody({ ...scheduleInspectionBody, inspection_date: value })
                  }
               />
            </div>

            <div className={cx("notesInput")}>
               <span>
                  <b>Access Details</b>
               </span>

               <textarea
                  placeholder="Enter your note here"
                  value={scheduleInspectionBody.access_details}
                  onChange={(e) =>
                     setScheduleInspectionBody({ ...scheduleInspectionBody, access_details: e.target.value })
                  }
               />
            </div>

            <div className={cx("notesInput")}>
               <span>
                  <b>Notes</b>
               </span>

               <textarea
                  placeholder="Enter your note here"
                  value={scheduleInspectionBody.notes}
                  onChange={(e) => setScheduleInspectionBody({ ...scheduleInspectionBody, notes: e.target.value })}
               />
            </div>
         </div>
         <SoSConfirmWrapper type="default" onConfirm={handleScheduleInspection}>
            <div className={cx("btnSave")}>
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn"),
                     disabled:
                        !scheduleInspectionBody.inspection_date ||
                        !scheduleInspectionBody.reno_person ||
                        !scheduleInspectionBody.assigned_poc,
                  }}
                  loading={isPendingScheduleInspection}
               >
                  Schedule Inspection
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>
      </div>
   );
};

type TransactionScheduleInspectionModalProps = {
   onClose: () => void;
};
