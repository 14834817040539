export const constants_paths = {
   HOME: "/",
   APPLICATION_LIBRARY: "/application-library",
   OFFERS: "/apps/offers",
   ASSETS: "/assets",

   APPS_MAP: "/apps/map",
   APPS_QEDITOR: "/apps/qeditor",
   APPS_TRANSACTION: "/apps/transaction",
   APPS_CSV_UPLOAD: "/apps/csv-upload",
   PROPERTY_VIABILITY: "/apps/property-viability",
   ACCOUNT_SETTINGS: "/account-settings",

   SVGAElement: "/svga-element",
   SIGN_IN: "/sign-in",
   UNAUTHROIZED: "/unauthroized",
   FORBIDDEN: "/forbidden",
   SOMETHING_WRONG: "/something-wrong",
   DEV: "/dev",
   DEVJIM: "/dev/jim",
   DEVOCEAN: "/dev/ocean",
   DEVTIMMY: "/dev/timmy",
   DEVNICK: "/dev/nick",
};
