import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { PVi_FilterSortTemplate_Data_Filter } from "./useMPVi_CreateFilterSortTemplate";
import { usePViContext } from "../../../../contexts/PViContext";

export const useMPVi_UpdateFilterSortTemplate = () => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const { fstid } = usePViContext().pviState;

   const mutation = useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: { type: "success", message: "Update Filter Sort Template is successful!" },
         });
      },
      onError: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: { type: "error", message: "Update Filter Sort Template is failed!" },
         });
      },
      mutationFn: ({ name, data }: PVi_UpdateFilterSortTemplate_Body) =>
         sosAxios
            .patch(`/property-viability/properties/filter-sort-templates/${fstid}`, { name, data })
            .then(({ data }: { data: PVi_UpdateFilterSortTemplate_Response }) => data),
   });
   return { ...mutation };
};

class PVi_UpdateFilterSortTemplate_Body_Dto_Data {
   filter?: PVi_FilterSortTemplate_Data_Filter;
   sort?: string;
}

export class PVi_UpdateFilterSortTemplate_Body {
   name?: string;
   data?: PVi_UpdateFilterSortTemplate_Body_Dto_Data;
}

export class PVi_UpdateFilterSortTemplate_Response {
   message!: string;
}
