import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "./../../../axios/useSosAxios";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useQOffers_GetLeads } from "../../queries/leads/useQOffers_GetLeads";
import { useQOffers_GetViableProperties } from "../../queries/useQOffers_GetViableProperties";
import { useSearchQueryParams_Offers } from "../../../react/useSearchQueryParams";
import { useQOffers_GetSourceProperties } from "../../queries/leads/useQOffers_GetSourceProperties";

export const useMOffers_Leads_CreateLead = () => {
   const { sosAxios } = useSosAxios();

   const { offersDispatch } = useOffersContext();

   const { toastDispatch } = useToastContext();

   const qOffersGetLeads = useQOffers_GetLeads();

   const qOfferViableProperties = useQOffers_GetViableProperties();

   const qOffersGetSourceProperties = useQOffers_GetSourceProperties();

   const setLid = useSearchQueryParams_Offers().lid[1];

   return useMutation({
      onSuccess: ({ lead }) => {
         qOffersGetLeads.refetch();
         qOffersGetSourceProperties.refetch();
         qOfferViableProperties.refetch();
         setLid(lead.lid);
         offersDispatch({ overwrite: { isOpenListViableProperties: false } });
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Create new lead is successful!",
            },
         });
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Create new lead is failed!",
            },
         }),

      mutationFn: (createLeadProps: TSoS_Offer_CreateLead_Props) =>
         sosAxios.post(`/offers/leads`, createLeadProps).then<TSoS_Offer_CreateLead_Response>(({ data }) => data),
   });
};

export type TSoS_Offer_CreateLead_Props = {
   pids: string[];
   sid: number;
};

export type TSoS_Offer_CreateLead_Lead = {
   lid: number;
};

export type TSoS_Offer_CreateLead_Response = { lead: TSoS_Offer_CreateLead_Lead };
