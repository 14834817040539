import { useState, useEffect, createContext, useContext } from "react";
import { useSosAxios } from "../hooks/axios/useSosAxios";
import { socket } from "../utils/socket";
import { useSoSAccessToken } from "../hooks/auth/useSoSAccessToken";

export const WebSocketContext = createContext({ isConnected: false, isRegistered: false, socket });

export const WebSocketProvider = ({ children }: { children: React.ReactNode }) => {
   const { sosAxios } = useSosAxios();

   const [isConnected, setIsConnected] = useState(socket.connected);
   const [isRegistered, setIsRegistered] = useState(false);

   const { sosAccessToken } = useSoSAccessToken();

   useEffect(() => {
      const handleDisconnect = () => {
         setIsRegistered(false);
         setIsConnected(false);
      };
      socket.on("connect", () => setIsConnected(true));
      socket.on("disconnect", handleDisconnect);
      socket.onAny((event) => console.log("socket.onAny", event));

      return () => {
         socket.off("connect", () => setIsConnected(true));
         socket.off("disconnect", handleDisconnect);
      };
   }, []);

   useEffect(() => {
      if (sosAccessToken && isConnected && !isRegistered) {
         setIsRegistered(true);
         sosAxios.post("/ws/register", { socketId: socket.id }).catch((er) => console.log({ er }));
      }
   }, [sosAccessToken, isConnected, isRegistered, sosAxios]);

   // const qPViConvGetThreads = useQConvo_Threads();
   // const { pid } = usePViContext().pviState;
   // const { lid, showDetailsModal: isLeadConvo } = useOffersContext().offersState;
   // useWebSocket_SubscribeChanges({
   //    data: { type: "threads", id: isLeadConvo ? lid : pid },
   //    onChanges: qPViConvGetThreads.refetch,
   // });

   // const { convoState } = useConvoContext();
   // const { tid } = convoState;
   // const qConvoGetCommentInThread = useQConvo_Comments();
   // useWebSocket_SubscribeChanges({ data: { type: "thread", id: tid }, onChanges: qConvoGetCommentInThread.refetch });

   return (
      <WebSocketContext.Provider value={{ isConnected, isRegistered, socket }}>{children}</WebSocketContext.Provider>
   );
};

export const useWebSocketContext = () => useContext(WebSocketContext);
