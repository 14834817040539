import classNames from "classnames/bind";
import styles from "./SoSSwitch.module.scss";

const cx = classNames.bind(styles);
export const SoSSwitch = ({ label, checked, inputProps, labelProps, onChange }: SoSSwichProps) => {
   return (
      <div className={cx("switch-container")}>
         {label && <span>{label}</span>}
         <label {...labelProps} className={cx("switch")}>
            <input {...inputProps} type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
            <span className={cx("slider")}></span>
         </label>
      </div>
   );
};
type SoSSwichProps = {
   label?: string;
   checked: boolean;
   onChange: (b: boolean) => void;
   labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
   inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};
