import { useQuery } from "@tanstack/react-query";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useSearchQueryParams_Offers } from "../../../react/useSearchQueryParams";

export const useQOffers_GetLeadAirTable = () => {
   const { sosAxios } = useSosAxios();
   const { offersState } = useOffersContext();
   const { pid } = offersState;
   const [lid] = useSearchQueryParams_Offers().lid;

   const query = useQuery({
      enabled: !!lid && !!pid,
      queryKey: [`useQOffers_GetLeadAirTable`, lid],
      staleTime: 500,
      queryFn: () =>
         sosAxios
            .get(`/offers/leads/${lid}/airtable`, { params: { pid } })
            .then<SoS_Offers_GetLeadAirTable_Response>(
               ({ data }: { data: SoS_Offers_GetLeadAirTable_Response }) => data
            ),
   });
   return query;
};

export class SoS_Offers_GetLeadAirTable_Inspection_Inspector {
   id!: string;
   email!: string;
   name!: string;
}

export class SoS_Offers_GetLeadAirTable_Inspection {
   record_id!: string;
   street_address!: string[];
   inspection_status!: string;
   estimated_total_cost?: number;
   inspector?: SoS_Offers_GetLeadAirTable_Inspection_Inspector;
   date_created!: string;
   date_walkthrough_performed?: string;
   date_inspection_submitted?: string;
   access_details?: string;
   inspection_notes?: string;
}

export type SoS_Offers_GetLeadAirTable_Response = {
   inspections: SoS_Offers_GetLeadAirTable_Inspection[];
};
