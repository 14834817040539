import classNames from "classnames/bind";
import { useMemo, useState } from "react";

import styles from "./ApplicationLibraryPage.module.scss";
import AppCard from "./AppCard/AppCard";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { TAppCategory, apps } from "../../constants/apps";
import { AppsDrawerProvider, useADContext } from "../../contexts/AppsDrawerContext";
import { useAppContext } from "../../contexts/AppContext";
import SoSVerticalMenu from "../../components/SoSVerticalMenu/SoSVerticalMenu";
import { SoSInputTitle } from "../../components/common/SoSInputTitle/SoSInputTitle";

const cx = classNames.bind(styles);

export const ApplicationLibraryPageWrapper = () => {
   return (
      <AppsDrawerProvider>
         <ApplicationLibraryPage />
      </AppsDrawerProvider>
   );
};

const ApplicationLibraryPage = () => {
   const [search, setSearch] = useState("");
   const { adDispatch } = useADContext();

   const handleSelectCategory = (type: TAppCategory) => {
      adDispatch({ overwrite: { selectedCategory: type } });
   };

   return (
      <div className={cx("appLibraryC")}>
         <div className={cx("container")}>
            <HeadingAndSearchBar search={search} setSearch={setSearch} />
            <div className={cx("body_context")}>
               <SoSVerticalMenu menu={menuCategories} onClick={(type) => handleSelectCategory(type as TAppCategory)} />
               <TabComponent search={search} />
            </div>
         </div>
      </div>
   );
};

export default ApplicationLibraryPage;

type HeadingAndSearchBarProps = {
   search: string;
   setSearch: (v: string) => void;
};
const HeadingAndSearchBar = ({ search, setSearch }: HeadingAndSearchBarProps) => {
   return (
      <div className={cx("heading_search")}>
         <span className={cx("heading")}>Application library</span>
         <div className={cx("input_search")}>
            <SoSInputTitle value={search} handleValue={setSearch} type="text" placeholder="Search app" />
            <img src={CF_CDN_URL("/assets/search.svg")} alt="" />
         </div>
      </div>
   );
};

const tabs = ["Explore", "Subscribed"] as const;
type TabComponentProps = {
   search: string;
};

const TabComponent = ({ search }: TabComponentProps) => {
   const { appState } = useAppContext();

   const { currentUser } = appState;

   const { subscribed_apps } = currentUser;

   const [tabSelected, setTabSelected] = useState<(typeof tabs)[number]>(tabs[0]);
   const { adState } = useADContext();
   const { selectedCategory } = adState;

   const subscribedApps = useMemo(() => subscribed_apps || [], [subscribed_apps]);
   const selectedApps = useMemo(
      () => (selectedCategory === "all" ? apps : apps.filter((app) => app.category === selectedCategory)),
      [selectedCategory]
   );
   const appsToRender = useMemo(
      () =>
         (tabSelected === "Explore"
            ? selectedApps
            : tabSelected === "Subscribed"
            ? selectedApps.filter((app) => subscribedApps.includes(app.id))
            : []
         ).filter((app) => app.name.toLowerCase().includes(search.toLowerCase())),
      [selectedApps, subscribedApps, tabSelected, search]
   );

   return (
      <div className={cx("tab")}>
         <ul className={cx("ul-tab")}>
            {tabs.map((elem, index) => {
               const style = elem === tabSelected ? "selected" : "";
               return (
                  <li key={index} className={cx("li-item", style)} onClick={() => setTabSelected(elem)}>
                     {elem}
                  </li>
               );
            })}
         </ul>
         <div className={cx("cardC")}>
            {appsToRender.length ? (
               appsToRender.map((app, i) => <AppCard appItem={app} key={i + app.name} />)
            ) : (
               <span className={cx("noApp")}> No app found </span>
            )}
         </div>
      </div>
   );
};

type TMenuCategory = {
   type: TAppCategory;
   title: string;
   icon: string;
};

const menuCategories: TMenuCategory[] = [
   {
      title: "All categories",
      type: "all",
      icon: "grid_view_black.svg",
   },
   {
      title: "Leads",
      type: "leads",
      icon: "people_alt_black.svg",
   },
   {
      title: "Underwriting",
      type: "underwriting",
      icon: "gpp_good_black.svg",
   },
   {
      title: "Transaction",
      type: "transaction",
      icon: "request_page_black.svg",
   },
   {
      title: "Renovation",
      type: "renovation",
      icon: "construction_black.svg",
   },
   {
      title: "Asset Management",
      type: "asset-management",
      icon: "maps_home_work_black.svg",
   },
];
