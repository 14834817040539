import classNames from "classnames/bind";
import { useState } from "react";
import { SHrPopup, SHrSosButton, SHrSosTabsBar, SHrSpinner } from "@simplyhomes/react";

import styles from "./SoSConversation.module.scss";

import { SoSConvoThreadCard } from "./SoSConvoThreadCard/SoSConvoThreadCard";
import { SoSConvoCreateNewThreadModal } from "./SoSConvoCreateNewThreadModal/SoSConvoCreateNewThreadModal";
import { ConvoProvider, ConvoProviderProps, useConvoContext } from "../../contexts/ConvoContext";
import { useQConvo_Threads } from "../../hooks/conversation-api/queries/threads/useQConvo_Threads";
import { useSoSOptions_Convo_Thread_Tags } from "../../hooks/queries/options/useQSoS_Options";
import { CF_CDN_URL, HS_CDN_URL } from "../../utils/CF_CDN_URL";
import { SoSInputTitle } from "../../components/common/SoSInputTitle/SoSInputTitle";
import SoSFilter from "../../components/common/SoSFilter/SoSFilter";
import { SoSInputSelect } from "../../components/common/SoSInputSelect/SoSInputSelect";
import {
   TConversations_GetSourceDeals_Contact,
   TConversations_GetSourceDeals_Deal,
   TConversations_GetSourceDeals_Property,
   useQConvo_Deals,
} from "../../hooks/conversation-api/queries/sellerOutreach/useQConvo_Deals";
import { useQPVi_Property } from "../../hooks/property-viability/queries/useQPVi_Property";
import { useMConvo_CreateDeal } from "../../hooks/conversation-api/mutates/sellerOutreach/useMConvo_CreateDeal";
import { useQOffers_Lead } from "../../hooks/offers/queries/leads/useQOffers_GetLead";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
import { formatDate } from "../OffersPage/OffersLeadCard/OffersLeadCard";

const cx = classNames.bind(styles);
const tabs = ["Conversations", "Seller Outreach"];
type SoSConversationProps = Pick<ConvoProviderProps, "state">;
export const SoSConversation = ({ state }: SoSConversationProps) => {
   const [activeTab, setActiveTab] = useState("Conversations");

   return (
      <ConvoProvider state={state}>
         <div className={cx("container", "_jr_pviConversation")}>
            <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={(v) => setActiveTab(v)} />
            {activeTab === "Conversations" ? <Conversation /> : <SellOutreach />}
         </div>
      </ConvoProvider>
   );
};

const Conversation = () => {
   const { convoState, convoDispatch } = useConvoContext();
   const { q, tags } = convoState;

   const [isOpenCreateNewThreadModal, setIsOpenCreateNewThreadModal] = useState(false);

   const qPViConvGetThreads = useQConvo_Threads();
   const { threads = [] } = qPViConvGetThreads;

   const qGetConvoTags = useSoSOptions_Convo_Thread_Tags();

   if (!qGetConvoTags.data?.options) return null;
   const tagOptions = qGetConvoTags.data?.options.map((o) => ({
      label: o.label,
      value: o.value,
   }));

   const tagList = tagOptions.filter((o) => tags.includes(o.value)).map((o) => o.label);

   const handleRemoveTag = (i: number) => {
      const newTags = tags.filter((_, index) => index !== i);
      convoDispatch({ overwrite: { tags: newTags } });
   };

   return (
      <div className={cx("conversationC")}>
         <div className={cx("btnNotifyAndSlack")}>
            <SHrSosButton
               buttonProps={{ className: cx("btn", "white"), onClick: () => setIsOpenCreateNewThreadModal(true) }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/edit_black.svg")} alt="" />
               </div>
               New thread
            </SHrSosButton>

            <SHrSosButton type="elevated" buttonProps={{ className: cx("btn"), onClick: () => {} }}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_validator/social_slack.svg")} alt="" />
               </div>
               Link to Slack
            </SHrSosButton>
         </div>
         <div className={cx("input_search")}>
            <SoSInputTitle
               value={q}
               type="text"
               handleValue={(q) => convoDispatch({ overwrite: { q } })}
               icon="search"
               placeholder="Search in conversation"
            />
         </div>
         <div className={cx("filterConversationC")}>
            <div className={cx("filterC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/local_offer_black.svg")} alt="" />
               </div>
               <div className={cx("input-search")}>
                  <SoSFilter
                     title="Filter"
                     isMulti
                     value={tags}
                     onChange={(op) => convoDispatch({ overwrite: { tags: op.map((o) => o.value) } })}
                     options={tagOptions}
                  />
               </div>

               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>

               <div className={cx("tagsC")}>
                  {tagList.map((t, i) => (
                     <div className={cx("filterTag")} key={i}>
                        <span>{t}</span>
                        <div className={cx("img-icon")} onClick={() => handleRemoveTag(i)}>
                           <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>

         <div className={cx("threadsC")}>
            {qPViConvGetThreads.isLoading ? (
               <div className={cx("spinnerC")}>
                  <SHrSpinner />
                  Loading...
               </div>
            ) : threads.length === 0 ? (
               <div className={cx("noThread")}>No thread</div>
            ) : (
               threads.map((thread, i) => <SoSConvoThreadCard key={i} tid={thread.tid} />)
            )}
         </div>

         <SHrPopup show={isOpenCreateNewThreadModal} handleShow={setIsOpenCreateNewThreadModal}>
            <SoSConvoCreateNewThreadModal onClose={() => setIsOpenCreateNewThreadModal(false)} mode="create" />
         </SHrPopup>
      </div>
   );
};

const SellOutreach = () => {
   const property = useQPVi_Property().data?.property;
   const lead = useQOffers_Lead().data?.lead;

   const sid = property?.source.sid || lead?.source.sid;

   const getDeals = useQConvo_Deals({ sid: sid });

   const createDeal = useMConvo_CreateDeal({ sid: sid! });

   return (
      <div className={cx("dealC")}>
         <div className={cx("dealDetailC")}>
            <div className={cx("btnHubspot")}>
               {!getDeals.data?.latestDeal && (
                  <SHrSosButton
                     buttonProps={{
                        className: cx("btn", "blue"),
                        onClick: () => {
                           createDeal.mutate();
                        },
                     }}
                  >
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/conversation/add_black.svg")} alt="" />
                     </div>
                     New Hubspot deal
                  </SHrSosButton>
               )}

               <SHrSosButton buttonProps={{ className: cx("btn") }}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/conversation/Hubspot.png")} alt="" />
                  </div>
                  Visit Hubspot
               </SHrSosButton>
            </div>
            {getDeals.isLoading ? (
               <div className={cx("loading")}>
                  <SHrSpinner /> <span>Loading Deals...</span>
               </div>
            ) : getDeals.data?.latestDeal ? (
               <LatestDeal deal={getDeals.data?.latestDeal} property={getDeals.data?.properties} />
            ) : (
               <div className={cx("noDeal")}>Found no deals related this source</div>
            )}
         </div>
         <div className={cx("otherC")}>
            <span>Other related deals</span>
            <div className={cx("dealOtherLst")}>
               {getDeals.data?.otherDeals.map((deal, i) => (
                  <Deal key={i} deal={deal} property={getDeals.data?.properties} />
               ))}
            </div>
         </div>
      </div>
   );
};
type TLatestDealProps = {
   deal: TConversations_GetSourceDeals_Deal;
   property: TConversations_GetSourceDeals_Property[];
};
const LatestDeal = ({ deal, property }: TLatestDealProps) => {
   const [openDeal, setOpenDeal] = useState(false);
   const [openProperties, setOpenProperties] = useState(false);
   const [openContact, setOpenContact] = useState(false);

   return (
      <div className={cx("dealInfor")}>
         <div className={cx("dealLink")}>
            <a href={HS_CDN_URL(`${deal.id}`)} className={cx("address")}>
               {deal.dealname}
            </a>
            <div className={cx("statusC")}>
               <div className={cx("icon", "active")}></div>
               <span className={cx("status")}>Active</span>
            </div>
         </div>
         <div className={cx("dealPipeline")}>
            <div className={cx("infor")}>
               <span className={cx("dealTitle")}>Stage</span>
               <div className={cx("deal")}>
                  <SoSInputSelect
                     options={["Aaaaaaa", "Bbbbbbb"]}
                     value={"Aaaaaaa"}
                     onChange={() => ({})}
                     isMulti={false}
                  />
               </div>
            </div>
            <div className={cx("infor")}>
               <span className={cx("dealTitle")}>Pipeline</span>
               <span className={cx("deal")}>{deal.pipeline}</span>
            </div>
         </div>
         <hr />
         <div className={cx("aboutDeal")}>
            <div className={cx("infor")} onClick={() => setOpenDeal(!openDeal)}>
               <span>About this deal</span>
               <div className={cx("img-icon")}>
                  <img
                     src={CF_CDN_URL(
                        `/assets/conversation/${openDeal ? "expand_more_black.svg" : "chevron_right_black.svg"}`
                     )}
                     alt=""
                  />
               </div>
            </div>
            <div
               className={cx("detail", {
                  open: openDeal,
               })}
            >
               <div className={cx("infor")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/conversation/person_black.svg")} alt="" />
                  </div>
                  <span className={cx("dealTitle")}>Deal owner</span>
                  <span>{deal.dealowner}</span>
               </div>
               <div className={cx("infor")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/conversation/today_black.svg")} alt="" />
                  </div>
                  <span className={cx("dealTitle")}>Date created</span>
                  <span>{formatDate(deal.createdate)}</span>
               </div>
               <div className={cx("infor")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/conversation/source_black.svg")} alt="" />
                  </div>
                  <span className={cx("dealTitle")}>Type</span>
                  <span>DTS</span>
               </div>
               <div className={cx("dealLink")}>
                  <div className={cx("infor")}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/conversation/home_black.svg")} alt="" />
                     </div>
                     <span className={cx("dealTitle")}>Properties included</span>
                     <span>
                        {property.length} {property.length > 1 ? "properties" : "property"}
                     </span>
                  </div>
                  <div className={cx("img-icon")} onClick={() => setOpenProperties(!openProperties)}>
                     <img
                        src={CF_CDN_URL(
                           `/assets/conversation/${
                              openProperties ? "expand_more_black.svg" : "chevron_right_black.svg"
                           }`
                        )}
                        alt=""
                     />
                  </div>
               </div>

               <div
                  className={cx("propertyC", {
                     open: openProperties,
                  })}
               >
                  {property.map((property, i) => (
                     <Property key={i} property={property} />
                  ))}
               </div>
            </div>
         </div>
         <hr />
         <div className={cx("aboutDeal")}>
            <div className={cx("infor")} onClick={() => setOpenContact(!openContact)}>
               <span>Contacts</span>
               <div className={cx("img-icon")}>
                  <img
                     src={CF_CDN_URL(
                        `/assets/conversation/${openContact ? "expand_more_black.svg" : "chevron_right_black.svg"}`
                     )}
                     alt=""
                  />
               </div>
            </div>
         </div>
         <div className={cx("contactC", { open: openContact })}>
            {deal.contacts?.map((contact, i) => (
               <Contact key={i} contact={contact} />
            ))}
         </div>
      </div>
   );
};
type TDealProps = {
   deal: TConversations_GetSourceDeals_Deal;
   property: TConversations_GetSourceDeals_Property[];
};
const Deal = ({ deal, property }: TDealProps) => {
   const [openDeal, setOpenDeal] = useState(false);
   const [openProperties, setOpenProperties] = useState(false);
   const [openContact, setOpenContact] = useState(false);
   return (
      <div className={cx("dealOther")}>
         <div className={cx("dealLink")}>
            <a href={HS_CDN_URL(`${deal.id}`)} className={cx("address")}>
               {deal.dealname}
            </a>
            <div className={cx("statusC")}>
               <div className={cx("icon", "active")}></div>
               <span className={cx("status")}>Active</span>
            </div>
         </div>
         <div className={cx("dealPipeline")}>
            <div className={cx("infor")}>
               <span className={cx("dealTitle")}>Stage</span>
               <div className={cx("deal")}>
                  <SoSInputSelect
                     options={["Aaaaaaa", "Bbbbbbb"]}
                     value={"Aaaaaaa"}
                     onChange={() => ({})}
                     isMulti={false}
                  />
               </div>
            </div>
            <div className={cx("infor")}>
               <span className={cx("dealTitle")}>Pipeline</span>
               <span className={cx("deal")}>{deal.pipeline}</span>
            </div>
         </div>
         <hr />
         <div className={cx("aboutDeal")}>
            <div className={cx("infor")} onClick={() => setOpenDeal(!openDeal)}>
               <span>About this deal</span>
               <div className={cx("img-icon")}>
                  <img
                     src={CF_CDN_URL(
                        `/assets/conversation/${openDeal ? "expand_more_black.svg" : "chevron_right_black.svg"}`
                     )}
                     alt=""
                  />
               </div>
            </div>
            <div className={cx("detail", { open: openDeal })}>
               <div className={cx("infor")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/conversation/person_black.svg")} alt="" />
                  </div>
                  <span className={cx("dealTitle")}>Deal owner</span>
                  <span>{deal.dealowner}</span>
               </div>
               <div className={cx("infor")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/conversation/today_black.svg")} alt="" />
                  </div>
                  <span className={cx("dealTitle")}>Date created</span>
                  <span>{formatDate(deal.createdate)}</span>
               </div>
               <div className={cx("infor")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/conversation/source_black.svg")} alt="" />
                  </div>
                  <span className={cx("dealTitle")}>Type</span>
                  <span>{deal.source_source}</span>
               </div>
               <div className={cx("dealLink")}>
                  <div className={cx("infor")}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/conversation/home_black.svg")} alt="" />
                     </div>
                     <span className={cx("dealTitle")}>Properties included</span>
                     <span>
                        {property.length} {property.length > 1 ? "properties" : "property"}
                     </span>
                  </div>
                  <div className={cx("img-icon")} onClick={() => setOpenProperties(!openProperties)}>
                     <img
                        src={CF_CDN_URL(
                           `/assets/conversation/${
                              openProperties ? "expand_more_black.svg" : "chevron_right_black.svg"
                           }`
                        )}
                        alt=""
                     />
                  </div>
               </div>

               <div className={cx("propertyC", { open: openProperties })}>
                  {property.map((property, i) => (
                     <Property key={i} property={property} />
                  ))}
               </div>
            </div>
         </div>
         <hr />
         <div className={cx("aboutDeal")}>
            <div className={cx("infor")} onClick={() => setOpenContact(!openContact)}>
               <span>Contacts</span>
               <div className={cx("img-icon")}>
                  <img
                     src={CF_CDN_URL(
                        `/assets/conversation/${openContact ? "expand_more_black.svg" : "chevron_right_black.svg"}`
                     )}
                     alt=""
                  />
               </div>
            </div>
         </div>
         <div className={cx("contactC", { open: openContact })}>
            {deal.contacts?.map((contact, i) => (
               <Contact key={i} contact={contact} />
            ))}
         </div>
      </div>
   );
};
type TPropertyProps = {
   property: TConversations_GetSourceDeals_Property;
};
const Property = ({ property }: TPropertyProps) => {
   return (
      <div className={cx("property")}>
         <div className={cx("propertyImg")}>
            <img src={property.street_view_url_150} alt="" />
         </div>
         <div className={cx("propertyDetail")}>
            <div className={cx("propertyLocation")}>
               <div className={cx("img-icon")}>
                  <img
                     className={cx("locationImg")}
                     src={CF_CDN_URL("/assets/conversation/location_on_black.svg")}
                     alt=""
                  />
               </div>
               <span className={cx("location")}>{property.address_one_line}</span>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/conversation/open_in_new_black.svg")} alt="" />
               </div>
            </div>
            <div className={cx("propertyOffer")}>
               <span className={cx("offer")}>Max Offer</span>
               <span className={cx("price")}>{SHu_js_currencyFormat(property.max_offer)}</span>
            </div>
         </div>
      </div>
   );
};

type TContactProps = {
   contact: TConversations_GetSourceDeals_Contact;
};
const Contact = ({ contact }: TContactProps) => {
   return (
      <div className={cx("contact")}>
         <div className={cx("avt")}>
            <img src="" alt="" />
         </div>
         <div className={cx("aboutContact")}>
            <div className={cx("contactInfor")}>
               <span className={cx("name")}>{contact.name || "N/A"}</span>
               <div className={cx("emailC")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/conversation/mail_black.svg")} alt="" />
                  </div>
                  <span className={cx("email")}>{contact.email}</span>
               </div>
            </div>
            <span className={cx("position")}>{contact.jobtitle}</span>
         </div>
      </div>
   );
};
