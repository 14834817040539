import { useInfiniteQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useAppContext } from "../../../contexts/AppContext";
import { useWebSocket_CatchEvent } from "../../webSocket/useWebSocket_CatchEvent";
import { useMemo } from "react";
import { useNotificationContext } from "../../../contexts/NotificationContext";

export const useQSoS_Notifications = () => {
   const { sosAxios } = useSosAxios();

   const { notificationState } = useNotificationContext();

   const { read, starred, limit } = notificationState;

   const { sub } = useAppContext().appState.currentUser;

   const query = useInfiniteQuery({
      enabled: !!sub,
      queryKey: [`useQSoS_Notifications`, { read, starred }],
      refetchInterval: 5 * 60 * 1000,
      queryFn: ({ pageParam: offset }) =>
         sosAxios
            .get(`/notifications`, { params: { read, starred, offset, limit } })
            .then(({ data }: { data: TNofs_GetNotifications_Response }) => {
               const notifications = data.payload.notifications;

               const totalUnread = data.payload.totalUnread;

               const notificationsMap = notifications.reduce<
                  Record<number, Nofs_GetNotifications_Response_Notification>
               >((acc, notification) => {
                  acc[notification.nid] = notification;
                  return acc;
               }, {});
               return { ...data, payload: { notifications, notificationsMap, totalUnread } };
            }),
      initialPageParam: 0,
      getNextPageParam: ({ metadata: { nextOffset } }) => {
         return nextOffset > 0 && nextOffset >= limit ? nextOffset : undefined;
      },
   });
   useWebSocket_CatchEvent({ room: "refresh:Notifications:", id: sub }, () => query.refetch());
   const notifications = useMemo(() => {
      return query.data?.pages.flatMap((page) => page.payload.notifications) || [];
   }, [query.data?.pages]);

   const totalUnread = useMemo(() => {
      return query.data?.pages[0].payload.totalUnread || 0;
   }, [query.data?.pages]);
   return { ...query, notifications, totalUnread };
};

export class Nofs_GetNotifications_Params {
   limit?: number;
   offset?: number;
   type?: string;
   read?: boolean;
   starred?: boolean;
}

class Nofs_GetNotifications_Response_Notification_Data_Property {
   pid!: string;
}

class Nofs_GetNotifications_Response_Notification_Data_Lead {
   lid!: number;
}

export type TNofs_GetNotifications_Response = {
   payload: {
      notifications: Nofs_GetNotifications_Response_Notification[];
      totalUnread: number;
   };
   metadata: { totalRows: number; nextOffset: number };
};

class Nofs_GetNotifications_Response_Notification_Data_Base {
   tid!: number;
   title!: string;
   commenter!: string;
   comment!: string;
   creator!: string;
   content!: string;
   _messageChunks!: Array<{ content: string; type?: "bold" }>;
   _link!: string;
}

export class Nofs_GetNotifications_Response_Notification {
   nid!: number;
   read!: boolean;
   starred!: boolean;
   created_at!: string;
   type!: "new-comment-in-subscribed-thread" | "mentioned-in-comment" | "mentioned-in-thread";
   data!: Nofs_GetNotifications_Response_Notification_Data_Base &
      (
         | Nofs_GetNotifications_Response_Notification_Data_Property
         | Nofs_GetNotifications_Response_Notification_Data_Lead
      );
}
