import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useAppContext } from "../../../contexts/AppContext";

export const useQSOS_Profile = () => {
   const { sosAxios } = useSosAxios();
   const { appState, appDispatch } = useAppContext();

   const { sosAccessToken } = appState;

   return useQuery({
      enabled: !!sosAccessToken,
      queryKey: ["useQSOS_Profile", sosAccessToken],
      retry: false,
      queryFn: () =>
         sosAxios
            .get("profile")
            .then<SoS_GetUserProfile_Response>(({ data }: { data: SoS_GetUserProfile_Response }) => {
               appDispatch({ overwrite: { currentUser: data.profile } });
               return data;
            }),
   });
};

class SoS_GetUserProfile_Settings {
   notifyToSlack?: boolean;
   notifyToEmail?: boolean;
}
export class SoS_GetUserProfile {
   email!: string;
   name!: string;
   picture?: string;
   avatar?: string;
   roles?: string[];
   subscribed_apps?: string[];
   sub!: string;
   phone?: string;
   slack_email?: string;
}

export class SoS_GetUserProfile_Response {
   profile!: SoS_GetUserProfile;
   settings!: SoS_GetUserProfile_Settings;
}
