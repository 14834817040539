import classNames from "classnames/bind";
import { useState } from "react";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import styles from "./NavHorizontal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useQSOS_Profile } from "../../../hooks/queries/user/useQSOS_Profile";
import { SHrSosButton, SHrSosTabsBar, SHrSpinner } from "@simplyhomes/react";
import { useQSoS_Notifications } from "../../../hooks/notifications/queries/useQSoS_Notifications";
import { useQ_GetUser } from "../../../hooks/queries/user/useQ_GetUser";
import { SHu_js_timeSince } from "@simplyhomes/utils";
import { useMSoS_MarkAllAsRead } from "../../../hooks/notifications/mutates/useMSoS_MarkAllAsRead";
import { handleString } from "../../../pages/SoSConversation/SoSConvoPostCommentModal/SoSConvoPostCommentModal";
import { useAppContext } from "../../../contexts/AppContext";
import { useSoSLogout } from "../../../hooks/auth/useSoSLogout";
import { useClickOutside } from "../../../hooks/react/useClickOutside";
import { useMSoS_UpdateNotification } from "../../../hooks/notifications/mutates/useMSoS_UpdateNotification";
import { useNotificationContext } from "../../../contexts/NotificationContext";

const cx = classNames.bind(styles);

const tabs = ["All notifications", "Not Read", "Starred"];

const NavHorizontal = () => {
   const nav = useNavigate();

   const { notificationDispatch } = useNotificationContext();

   const { appState, appOverwrite } = useAppContext();

   const { isShowVerticalNavigation } = appState;

   const { logOut } = useSoSLogout();

   const qUserProfile = useQSOS_Profile();

   const { data: userProfile } = qUserProfile;

   const qGetNotifications = useQSoS_Notifications();

   const { mutate: mutateMarkAllAsRead, isPending: isPendingMarkAllAsRead } = useMSoS_MarkAllAsRead();

   const [activeTab, setActiveTab] = useState("All notifications");

   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

   const [isShowNotificationOpen, setIsShowNotificationOpen] = useState(false);

   const profile = userProfile?.profile;

   if (!profile) return null;

   const { picture, avatar } = profile;

   const userMenuItems: { icon: string; title: string; cb: () => void }[] = [
      { icon: "settings_primary.svg", title: "Account settings", cb: () => nav("/account-settings") },
      { icon: "help_center_primary.svg", title: "Support center", cb: () => nav("/support-center") },
   ];

   const handleChangeTypeNotification = (type: string) => {
      setActiveTab(type);
      const overwrite = {
         read: type === "Not Read" ? false : undefined,
         starred: type === "Starred" ? true : undefined,
      };

      notificationDispatch({ overwrite });
   };

   return (
      <div className={cx("contentC")}>
         <div className={cx("leftC")}>
            <div
               className={cx("burger")}
               onClick={() => appOverwrite({ isShowVerticalNavigation: !isShowVerticalNavigation })}
            >
               <img
                  src={CF_CDN_URL(`/assets/${isShowVerticalNavigation ? "clear_black.svg" : "menu_black.svg"}`)}
                  alt=""
               />
            </div>
            <div className={cx("logo")}>
               <img src={CF_CDN_URL("/assets/nav_logo.svg")} alt="" />
               <span>Simply OS</span>
            </div>
         </div>

         <div className={cx("notification")}>
            <div className={cx("bellAndNumber")}>
               <div className={cx("new-messages")}>{qGetNotifications.totalUnread}</div>

               <div
                  className={cx("img-icon")}
                  onClick={() => {
                     setIsShowNotificationOpen(!isShowNotificationOpen);
                  }}
               >
                  <img src={CF_CDN_URL("/assets/notifications_snow.svg")} alt="" />
               </div>
            </div>

            <div className={cx("avt-notification")} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
               <div>
                  <img src={avatar || picture} alt="" />
               </div>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/arrow_drop_down.svg")} alt="" />
               </div>
            </div>

            <div
               className={cx("menu-item-notication", {
                  open: isShowNotificationOpen,
               })}
            >
               <div className={cx("topC")}>
                  <span className={cx("heading")}>Notifications</span>
                  <SHrSosButton
                     type="text"
                     loading={isPendingMarkAllAsRead}
                     buttonProps={{ className: cx("mark"), onClick: () => mutateMarkAllAsRead() }}
                  >
                     Mark all as read
                  </SHrSosButton>
               </div>
               <div className={cx("tabs")}>
                  <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={(v) => handleChangeTypeNotification(v)} />
               </div>
               {activeTab && activeTab === "All notifications" && (
                  <div className={cx("listItemsC")}>
                     {qGetNotifications.isLoading ? (
                        <div className={cx("loading")}>
                           <SHrSpinner /> <span>Loading Properties...</span>
                        </div>
                     ) : qGetNotifications.notifications?.length ? (
                        qGetNotifications.notifications?.map((item, i) => (
                           <Notification
                              key={item.nid}
                              nid={item.nid}
                              setShowNotifications={setIsShowNotificationOpen}
                           />
                        ))
                     ) : (
                        <div className={cx("noData")}>No Notification</div>
                     )}

                     <div className={cx("loadBtn")}>
                        <SHrSosButton
                           loading={qGetNotifications.isLoading}
                           buttonProps={{
                              className: cx(
                                 cx("load-more", {
                                    hidden: !qGetNotifications.hasNextPage || qGetNotifications.isFetchingNextPage,
                                 })
                              ),
                              onClick: () => qGetNotifications.fetchNextPage(),
                           }}
                        >
                           Load More
                        </SHrSosButton>
                     </div>
                  </div>
               )}

               {activeTab && activeTab === "Not Read" && (
                  <div className={cx("listItemsC")}>
                     {qGetNotifications.isLoading ? (
                        <div className={cx("loading")}>
                           <SHrSpinner /> <span>Loading Notification...</span>
                        </div>
                     ) : qGetNotifications.notifications.length ? (
                        qGetNotifications.notifications.map((item, i) => (
                           <Notification key={i} nid={item.nid} setShowNotifications={setIsShowNotificationOpen} />
                        ))
                     ) : (
                        <div className={cx("noData")}>No Notification</div>
                     )}
                     <div className={cx("loadBtn")}>
                        <SHrSosButton
                           loading={qGetNotifications.isLoading}
                           buttonProps={{
                              className: cx(
                                 cx("load-more", {
                                    hidden: !qGetNotifications.hasNextPage || qGetNotifications.isFetchingNextPage,
                                 })
                              ),
                              onClick: () => qGetNotifications.fetchNextPage(),
                           }}
                        >
                           Load More
                        </SHrSosButton>
                     </div>
                  </div>
               )}

               {activeTab && activeTab === "Starred" && (
                  <div className={cx("listItemsC")}>
                     {qGetNotifications.isLoading ? (
                        <div className={cx("loading")}>
                           <SHrSpinner /> <span>Loading Notification...</span>
                        </div>
                     ) : qGetNotifications.notifications.length ? (
                        qGetNotifications.notifications.map((item, i) => (
                           <Notification key={i} nid={item.nid} setShowNotifications={setIsShowNotificationOpen} />
                        ))
                     ) : (
                        <div className={cx("noData")}>No Notification</div>
                     )}
                     <div className={cx("loadBtn")}>
                        <SHrSosButton
                           loading={qGetNotifications.isLoading}
                           buttonProps={{
                              className: cx(
                                 cx("load-more", {
                                    hidden: !qGetNotifications.hasNextPage || qGetNotifications.isFetchingNextPage,
                                 })
                              ),
                              onClick: () => qGetNotifications.fetchNextPage(),
                           }}
                        >
                           Load More
                        </SHrSosButton>
                     </div>
                  </div>
               )}
            </div>

            <ul
               className={cx("menu-item", { open: isDropdownOpen })}
               onMouseLeave={() => setIsDropdownOpen(false)}
               onClick={() => setIsDropdownOpen(false)}
            >
               <li onClick={() => nav("/account-settings")}>
                  <div className={cx("user-infor")}>
                     <div className={cx("img-icon-avatar")}>
                        <img src={avatar || picture} alt="" />
                     </div>
                     <div className={cx("infor")}>
                        <b>{profile.name}</b>
                        <span>{profile.email}</span>
                     </div>
                  </div>
               </li>

               {userMenuItems.map((item, index) => (
                  <li key={index + item.title} onClick={item.cb}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL(`/assets/${item.icon}`)} alt="" />
                     </div>
                     <span>{item.title}</span>
                  </li>
               ))}

               <li className={cx("signout")} onClick={() => logOut()}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL(`/assets/exit_to_app_primary.svg`)} alt="" />
                  </div>
                  <span>Sign out</span>
               </li>
            </ul>
         </div>
      </div>
   );
};

type NotificationProps = {
   nid: number;
   setShowNotifications: (value: boolean) => void;
};

const Notification = ({ nid, setShowNotifications }: NotificationProps) => {
   const nav = useNavigate();

   const [dropdownVisible, setDropdownVisible] = useState(false);

   const { ref } = useClickOutside({
      onClickOutside: () => {
         setDropdownVisible(false);
      },
   });

   const qGetNotifications = useQSoS_Notifications();

   const item = qGetNotifications.data?.pages.flatMap((page) => page.payload.notifications).find((i) => i.nid === nid);

   const getUser = useQ_GetUser({ uid: item?.data.commenter || item?.data.creator || "" });

   const { mutate: mutateUpdateNotification, isPending } = useMSoS_UpdateNotification();

   if (!item) return null;

   const { created_at, data, type, read, starred } = item;

   const { tid, comment, title, content, _messageChunks, _link } = data || {};

   const user = getUser.data?.user;

   if (!user) return null;

   const { picture, name } = user;

   const timestamp = new Date(created_at).getTime();

   const handleNotification = () => {
      setShowNotifications(false);
      if ("pid" in data) {
         nav({
            pathname: "/apps/property-viability",
            search: createSearchParams({ convo_tid: tid.toString(), pvi_pid: data.pid }).toString(),
         });
         return;
      }
      if ("lid" in data) {
         nav({
            pathname: "/apps/offers",
            search: createSearchParams({ convo_tid: tid.toString(), offers_lid: data.lid.toString() }).toString(),
         });
         return;
      }
      mutateUpdateNotification({ nid: item.nid, read: true });
   };

   const handleMutateReadStatus = () => {
      mutateUpdateNotification({ nid: item.nid, read: !read });
      setDropdownVisible(false);
   };

   const handleStarredNotification = () => {
      mutateUpdateNotification({ nid: item.nid, starred: !starred });
   };

   return (
      <>
         <div className={cx("itemC", { unread: !read })}>
            {starred ? (
               <button
                  type="button"
                  disabled={isPending}
                  className={cx("starredIcon")}
                  onClick={handleStarredNotification}
               >
                  <img src={CF_CDN_URL("/assets/property_viability/starred.svg")} alt="" />
               </button>
            ) : (
               <button
                  type="button"
                  className={cx("starIcon")}
                  disabled={isPending}
                  onClick={handleStarredNotification}
               >
                  <img src={CF_CDN_URL("/assets/property_viability/star_outline_black.svg")} alt="" />
               </button>
            )}

            <div className={cx("img-icon-avatar")}>
               <img src={picture} alt="" />
            </div>

            {_messageChunks ? (
               <div className={cx("messages")}>
                  <Link
                     to={_link}
                     className={cx("messageDetail")}
                     onClick={() => {
                        mutateUpdateNotification({ nid: item.nid, read: !read });
                        setShowNotifications(false);
                     }}
                  >
                     {_messageChunks.map((chunk, i) => (
                        <span key={i} className={cx(chunk.type)}>
                           {chunk.content.includes("@") ? handleString(chunk.content) : chunk.content + " "}
                        </span>
                     ))}
                  </Link>
                  <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>
               </div>
            ) : type === "new-comment-in-subscribed-thread" ? (
               <div className={cx("messages")}>
                  <span onClick={handleNotification} className={cx("messageDetail")}>
                     <b>{name}</b> commented on your subscribed thread (<b>{title}</b>): {handleString(comment)}
                  </span>
                  <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>
               </div>
            ) : type === "mentioned-in-comment" ? (
               <div className={cx("messages")}>
                  <span onClick={handleNotification} className={cx("messageDetail")}>
                     <b>{name}</b> mentioned you in a comment: {handleString(comment)}
                  </span>
                  <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>
               </div>
            ) : (
               <div className={cx("messages")}>
                  <span onClick={handleNotification} className={cx("messageDetail")}>
                     <b>{name}</b> mentioned you in a new thread (<b>{title}</b>): {handleString(content)}
                  </span>
                  <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>
               </div>
            )}

            <button type="button" className={cx("img-icon-dots")} onClick={() => setDropdownVisible(!dropdownVisible)}>
               <img src={CF_CDN_URL("/assets/more_horiz_black.svg")} alt="" />
            </button>

            <div className={cx("dropdownC", { dropdownVisible })} ref={ref}>
               <div className={cx("dropdown-menu")}>
                  <SHrSosButton
                     type="text"
                     buttonProps={{ className: cx("dropdown-item"), onClick: handleMutateReadStatus }}
                  >
                     {isPending ? (
                        <div className={cx("loading")}>
                           <SHrSpinner />
                        </div>
                     ) : (
                        <span> {read ? "Mark as unread" : "Mark as read"}</span>
                     )}
                  </SHrSosButton>
               </div>
            </div>
         </div>
         {/* <hr className={cx("divider")} /> */}
      </>
   );
};

export default NavHorizontal;
