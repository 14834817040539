import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../contexts/ToastContext";
import { useSosAxios } from "../../axios/useSosAxios";
import { Database_Column, Database_Table } from "./utils";

export const useMDatabase_UploadImage = (p?: useMDatabase_UploadImage_Params) => {
   const { table, column, identifiers, onComplete = () => {} } = p || {};
   const { sosAxios } = useSosAxios({ redirectOnError: false });
   const { toastDispatch } = useToastContext();
   const mutationKey = [table, column, identifiers];

   const mutation = useMutation({
      mutationKey,
      onSuccess: () => {
         onComplete(true);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "success", message: "Image Uploaded" } });
      },
      onError: () => {
         onComplete(false);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "error", message: "Invalid Image" } });
      },
      mutationFn: ({ images }: { images: FileList }) => {
         if (images.length === 0) return Promise.reject("No image selected");

         const newImg = images[0];
         const formData = new FormData();

         formData.append("image", newImg);

         return sosAxios
            .post("/database/image", formData, {
               headers: { "Content-Type": "multipart/form-data" },
               params: { table, column, identifiers },
            })
            .then<DatabaseService_UploadImage_Response_Dto>(({ data }) => data);
      },
   });
   const isMutating = useIsMutating({ mutationKey });

   return { ...mutation, isMutating };
};

export type useMDatabase_UploadImage_Params = {
   table: Database_Table;
   column: Database_Column;
   identifiers: { column: string; id: string | number }[];
   onComplete?: (isSuccess: boolean) => void;
};

export class DatabaseService_UploadImage_Response_Dto {
   iuid!: string;
}
