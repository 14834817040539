import { GoogleMap } from "@react-google-maps/api";
import { ComponentProps, useCallback, useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/AppContext";

export const GoogleMapWrapper = (p: TMapProps) => {
   const { isGoogleMapsLoaded } = useAppContext().appState;
   const { mapProps, children, center, onCenterChange, onZoomChange, zoom } = p;

   const mapRef = useRef<google.maps.Map | null>(null);

   const onLoad = useCallback(
      (map: google.maps.Map) => {
         mapRef.current = map;
         if (mapProps?.onLoad) {
            mapProps.onLoad(map);
         }
      },
      [mapProps]
   );

   useEffect(() => {
      if (mapRef.current) {
         const listener = mapRef.current.addListener("center_changed", () => {
            if (mapRef.current) {
               const newCenter = mapRef.current.getCenter();
               if (newCenter && onCenterChange) onCenterChange(newCenter.toJSON());
               const newZoom = mapRef.current.getZoom();
               if (newZoom && onZoomChange) onZoomChange(newZoom);
            }
         });
         return () => google.maps.event.removeListener(listener);
      }
   }, [onCenterChange, onZoomChange]);

   if (!isGoogleMapsLoaded) return null;
   return (
      <GoogleMap
         mapContainerStyle={{ width: "100%", height: "100%" }}
         center={center}
         zoom={zoom}
         options={{ keyboardShortcuts: false, clickableIcons: false }}
         {...mapProps}
         onLoad={onLoad}
      >
         {children}
      </GoogleMap>
   );
};

type TMapProps = {
   zoom: number;
   center: google.maps.LatLngLiteral;
   onCenterChange?: (center: google.maps.LatLngLiteral) => void;
   onZoomChange?: (zoom: number) => void;
   mapProps?: ComponentProps<typeof GoogleMap>;
   children?: React.ReactNode;
};
