import { useSearchQueryParams } from "../../react/useSearchQueryParams";

export const useOffersQueryParams = () => {
   const { useSingleQueryParamsState, useMultiQueryParamsState } = useSearchQueryParams();
   const [assignee, setAssigneeIds] = useMultiQueryParamsState("assignee");
   const [cids, setContactIds] = useMultiQueryParamsState("cids");
   const [leadName, setLeadName] = useSingleQueryParamsState("leadName");

   const filters = { assignee, cids, leadName };
   return { filters, ...filters, setAssigneeIds, setContactIds, setLeadName };
};
