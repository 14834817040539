import classNames from "classnames/bind";
import { useState } from "react";
import styles from "./OffersCreateNewLeadModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { OffersPropertiesSelection } from "../OffersPropertiesSelection/OffersPropertiesSelection";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useQOffers_GetSourceProperties } from "../../../hooks/offers/queries/leads/useQOffers_GetSourceProperties";
import { useMOffers_Leads_CreateLead } from "../../../hooks/offers/mutates/leads/useMOffers_Leads_CreateLead";
import { SoSInputTitle } from "../../../components/common/SoSInputTitle/SoSInputTitle";
import { formatDate } from "../OffersLeadCard/OffersLeadCard";
import { useOffersContext } from "../../../contexts/OffersContext";

const cx = classNames.bind(styles);

export const OffersCreateNewLeadModal = ({ onClose, pid }: TOffersCreateModalProps) => {
   const [selectedProperties, setSelectedProperties] = useState<string[]>([pid]);

   const { sid } = useOffersContext().offersState;

   const qOffersGetSourceProperties = useQOffers_GetSourceProperties();

   const [search, setSearch] = useState("");

   const { mutate, isPending } = useMOffers_Leads_CreateLead();

   const handleCreateLead = () => {
      mutate({ pids: selectedProperties, sid });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/contact_phone_black.svg")} alt="" />
            </div>
            <span>Create a Lead</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("contentC")}>
            <div className={cx("addMorePropertiesC")}>
               <span>What properties would you like to add?</span>
               <p>You can include multiple properties, please select any that you want to include.</p>
            </div>

            <div className={cx("input_search")}>
               <SoSInputTitle
                  value={search}
                  type="text"
                  handleValue={setSearch}
                  icon="search"
                  placeholder="Search a contact name, email or phone number"
               />
            </div>

            <div className={cx("sourceInfoC")}>
               <div className={cx("sourceTypeC")}>
                  <span>
                     Source type: <b>{qOffersGetSourceProperties.data?.source.source || "N/A"}</b>
                  </span>
                  <span>
                     Date submitted: <b>{formatDate(qOffersGetSourceProperties.data?.source.created_at || "")}</b>
                  </span>
               </div>

               {qOffersGetSourceProperties.isLoading ? (
                  <div className={cx("loading")}>
                     <SHrSpinner />
                  </div>
               ) : qOffersGetSourceProperties.data?.source.contacts.length === 0 ? (
                  <div className={cx("noContact")}>No source's contact found</div>
               ) : (
                  qOffersGetSourceProperties.data?.source.contacts.map((contact, i) => (
                     <div className={cx("contactC")} key={i}>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/person_black.svg")} alt="" />
                           </div>
                           <span>{contact.name || "Ethan Harrison"}</span>
                        </div>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/email_black.svg")} alt="" />
                           </div>
                           <span>{contact.email}</span>
                        </div>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/phone_black.svg")} alt="" />
                           </div>
                           <span>{contact.phone_primary || "(000) 000 0000"}</span>
                        </div>
                     </div>
                  ))
               )}
            </div>

            <div className={cx("propertiesC")}>
               {qOffersGetSourceProperties.isLoading ? (
                  <div className={cx("loading")}>
                     <SHrSpinner sideLength="32px" /> <span>Loading Properties...</span>
                  </div>
               ) : (
                  qOffersGetSourceProperties.data?.properties.map((item, i) => (
                     <OffersPropertiesSelection
                        type="select"
                        key={i}
                        property={item}
                        value={selectedProperties.includes(item.pid)}
                        onSelectProperty={(value) => {
                           if (value) setSelectedProperties([...selectedProperties, item.pid]);
                           else setSelectedProperties(selectedProperties.filter((p) => p !== item.pid));
                        }}
                     />
                  ))
               )}
            </div>
         </div>

         <SoSConfirmWrapper
            title="Are you sure you want to create a new lead?"
            content="This action allows to create a new lead."
            type="default"
            onConfirm={() => handleCreateLead()}
         >
            <div className={cx("btnSave")}>
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn"),
                     disabled: !selectedProperties.length,
                  }}
                  loading={isPending}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
                  </div>
                  Create Lead
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>
      </div>
   );
};

type TOffersCreateModalProps = {
   onClose: () => void;
   pid: string;
   sid: number;
};
