import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import styles from "./DevOcean.module.scss";
import { useState } from "react";
import { SoSInputTitle } from "../../components/common/SoSInputTitle/SoSInputTitle";
import { useQSoS_UserByEmail } from "../../hooks/queries/user/useQSoS_UserByEmail";
import { useQ_Users } from "../../hooks/queries/user/useQ_Users";
import { SoSCopyToClipboard } from "../../components/common/SoSCopyToClipboard/SoSCopyToClipboard";
import { useQOffers_GetTermTemplates } from "../../hooks/offers/queries/termTemplate/useQOffers_GetTermTemplates";
import { useQOffers_GetLeads } from "../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { useToastContext } from "../../contexts/ToastContext";
import { SoSConfirmWrapper } from "../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSToolbox } from "../../components/SoSToolBox/SoSToolBox";
import { DropdownIndicator, SoSInputSelect } from "../../components/common/SoSInputSelect/SoSInputSelect";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { components } from "react-select";
import { SoSIconSelect, TSoSIconSelect_option } from "../../components/common/SoSIconSelect/SoSIconSelect";
import classNames from "classnames/bind";
import { SoSSwitch } from "../../components/common/SoSSwitch/SoSSwitch";

const cx = classNames.bind(styles);

export const DevOcean = () => {
   const { toatsState, toastDispatch } = useToastContext();
   // const userRes = useQSoS_UserByEmail({ email: "ocean.nguyen@simplyhomes.com" });
   // // console.log(userRes.data?.user);

   // const termTemplatesRes = useQOffers_GetTermTemplates();
   // // console.log(termTemplatesRes.data?.templates);

   // const usersRes = useQ_Users();

   // // console.log(usersRes.data?.users);

   // const leadsRes = useQOffers_GetLeads();
   // // console.log(leadsRes.data?.leads);

   const [isPopupOpen, setIsPopupOpen] = useState(false);

   const addErrorToast = () => {
      toastDispatch({
         type: "ADD_TOAST",
         payload: {
            type: "error",
            onAction: () => alert("Action clicked!"),
            message: "toast " + toatsState.toasts.length,
         },
      });
   };

   const addSuccessToast = () => {
      toastDispatch({
         type: "ADD_TOAST",
         payload: {
            type: "success",
            onAction: () => alert("Action clicked!"),
            message: "toast " + toatsState.toasts.length,
         },
      });
   };
   const [isChecked, setIsChecked] = useState(false);

   const items = [
      {
         icon: "export.svg",
         onClick: () => alert("Tool 1 clicked"),
      },
      {
         title: "Helps",
         icon: "info.svg",
         onClick: () => alert("Tool 2 clicked"),
      },
      {
         title: "Services",
         icon: "notes.svg",
         onClick: () => alert("Tool 3 clicked"),
      },
      {
         title: "About Company",
         icon: "refresh.svg",
         onClick: () => alert("Tool 3 clicked"),
      },
      {
         title: "Phone & Email",
         icon: "save.svg",
         onClick: () => alert("Tool 3 clicked"),
      },
   ];

   const [selectedValue, setSelectedValue] = useState<TSoSIconSelect_option>({
      label: "Option 1",
      value: "1",
      icon: CF_CDN_URL("/assets/search.svg"),
   });

   const handleValueChange = (value: any) => {
      console.log(value);
      setSelectedValue(value);
   };

   return (
      <div className={`${styles.contentC}`}>
         {/* <SoSInputSelect
            isMulti={false}
            options={[
               { label: "Option 1", value: "1" },
               { label: "Option 2", value: "2" },
               { label: "Option 3", value: "3" },
            ]}
            value="1"
            onChange={() => {}}
            customStyle="primary"
            dropdownIndicator="customIcon"
            SelectProps={{
               components: {
                  DropdownIndicator: (props) => (
                     <DropdownIndicator
                        {...props}
                        dropdownIndicator="customIcon"
                        customIcon={<img src={CF_CDN_URL("/assets/search.svg")} alt="" />}
                     />
                  ),
               },
            }}
         /> */}
         {/* <SoSIconSelect
            value={selectedValue.value}
            options={[
               { label: "Option 1", value: "1", icon: CF_CDN_URL("/assets/search.svg") },
               { label: "Option 2", value: "2", icon: CF_CDN_URL("/assets/property_viability/filter_list_black.svg") },
               { label: "Option 3", value: "3", icon: CF_CDN_URL("/assets/search.svg") },
            ]}
            onChange={handleValueChange}
         /> */}
         <Nofs />
         {/* <SoSToolbox items={items} /> */}
         {/* <div>
            <SoSSwitch checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
         </div>
         <SHrSosButton type="bland">Review Property</SHrSosButton>
         <SHrSosButton type="dangerous" buttonProps={{ onClick: () => addErrorToast() }}>
            Review Property
         </SHrSosButton>
         <SHrSosButton type="elevated" buttonProps={{ onClick: () => addSuccessToast() }}>
            Review Property
         </SHrSosButton>
         <SHrSosButton type="filled">Review Property</SHrSosButton>
         <SHrSosButton type="outlined">Review Property</SHrSosButton>

         <SoSConfirmWrapper type="default" onCancel={() => {}} onConfirm={() => {}}>
            <SHrSosButton type="positive">Review Property</SHrSosButton>
         </SoSConfirmWrapper>

         <SHrSosButton type="text">Review Property</SHrSosButton>
         <SHrSosButton type="tonal" buttonProps={{ onClick: () => setIsPopupOpen(!isPopupOpen) }}>
            Review Property
         </SHrSosButton>
         <SoSInputTitle title="Question ID" type="text" value={""} handleValue={() => {}} /> */}
         {/* <SHrPopup show={isPopupOpen}>
            <div>Popup Content</div>
            <SHrSosButton type="filled" buttonProps={{ onClick: () => setIsPopupOpen(!isPopupOpen) }}>
               Close
            </SHrSosButton>
         </SHrPopup> */}

         {/* <SHrPopup show={isPopupOpen}>
            <PViNewRentTypeModal onClose={() => setIsPopupOpen(!isPopupOpen)} />
         </SHrPopup> */}
         {/* <SoSCopyToClipboard value="ppp" /> */}
         {/* <div style={{ display: "flex" }}>
            <OffersPropertyCard property={1} />
            <OffersPropertyCard property={1} />
         </div> */}
      </div>
   );
};

const Nofs = () => {
   return (
      <div className={cx("container")}>
         <NotificationSetting />
         <hr />
         <NotificationSetting />
         <div className={cx("bottomC")}></div>
      </div>
   );
};

const NotificationSetting = () => {
   const [expanded, setExpanded] = useState(false);

   return (
      <div className={cx("notificationC")}>
         <div className={cx("topC")}>
            <div className={cx("iconC")}>
               <img src={CF_CDN_URL("/assets/account_settings/slack_icon.svg")} alt="slack" />
            </div>
            <div className={cx("textC")}>
               <div>
                  <b>Connect to Slack</b>
               </div>
               <div>Use your Slack account to get latest notifications from SOS</div>
            </div>
            <div className={cx("expandIconC")} onClick={() => setExpanded(!expanded)}>
               <img
                  src={CF_CDN_URL(
                     `/assets/account_settings/${expanded ? "expand_more_black.svg" : "chevron_right_black.svg"}`
                  )}
                  alt="slack"
               />
            </div>
         </div>

         {expanded && (
            <div className={cx("expanded")}>
               <div className={cx("inputC")}>
                  <SoSInputTitle
                     isBoldTitle={false}
                     title="Slack Email"
                     type="text"
                     value={""}
                     handleValue={() => {}}
                     placeholder="example@company.com"
                  />
               </div>
               <div className={cx("switchC")}>
                  <SoSSwitch checked={true} onChange={() => {}} label="Turn on notifications on Slack" />
               </div>
            </div>
         )}
      </div>
   );
};
