import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useQPVi_Property } from "../../queries/useQPVi_Property";
import { useSearchQueryParams_PVi } from "../../../react/useSearchQueryParams";

export const useMPVi_Property_ResetExpenses = () => {
   const { toastDispatch } = useToastContext();

   const [pid] = useSearchQueryParams_PVi().pid;
   
   const { sosAxios } = useSosAxios();
   const qPVi_Property = useQPVi_Property();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: { type: "success", message: "Reset Expenses Table is successful!" },
         });
         qPVi_Property.refetch();
      },
      onError: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: { type: "error", message: "Reset Expenses Table is failed!" },
         });
      },
      mutationFn: (p: PVi_ResetExpensesInUnderWritingModel_Params) =>
         sosAxios
            .patch(`/property-viability/properties/${pid}/reset-expenses`, undefined, { params: p })
            .then<PVi_ResetExpensesInUnderWritingModel_Response>(({ data }) => data),
   });
};

export type PVi_ResetExpensesInUnderWritingModel_Response = { message: string };

export type PVi_ResetExpensesInUnderWritingModel_Params = { modelIndex?: number };
