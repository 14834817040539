import classNames from "classnames/bind";
import styles from "./OffersLeadDetail.module.scss";
import { useOffersContext } from "../../../contexts/OffersContext";
import { OffersLeadDetailSetting } from "./OffersLeadDetailSetting/OffersLeadDetailSetting";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { useQOffers_Lead } from "../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { database_helpers_createPayloadHelper as db } from "../../../hooks/database/mutations/utils";
import { useMDatabase_UpdateValue } from "../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { useQOffers_GetLeads } from "../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { useSoSOptions_Offers_Leads_Statuses } from "../../../hooks/queries/options/useQSoS_Options";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { SoSCopyToClipboard } from "../../../components/common/SoSCopyToClipboard/SoSCopyToClipboard";
import { useSearchQueryParams_Offers } from "../../../hooks/react/useSearchQueryParams";
import { SoSSwitch } from "../../../components/common/SoSSwitch/SoSSwitch";

export const cx = classNames.bind(styles);

export const OffersLeadDetail = () => {
   const [lid] = useSearchQueryParams_Offers().lid;
   return (
      <div className={cx("rightC", "_jr_offersLeadDetail")}>
         {lid ? <OffersLeadDetailTop /> : null}
         {lid ? <OffersLeadDetailSetting /> : <NoSelectedLead />}
      </div>
   );
};

const OffersLeadDetailTop = () => {
   const { offersState, offersDispatch } = useOffersContext();

   const qLeadStatusesOptions = useSoSOptions_Offers_Leads_Statuses().data?.options || [];

   const { isViewComment } = offersState;

   const qOfferLead = useQOffers_Lead();
   const { name, status } = qOfferLead.data?.lead || {};
   const statusLabel = qLeadStatusesOptions.find((o) => o.value === status)?.label || status;

   const qOfferLeads = useQOffers_GetLeads();

   const lead_id = { column: "lid", id: qOfferLead.data?.lead.lid || "" };

   const db_L = db("sos_leads", [lead_id], () => {
      qOfferLead.refetch();
      qOfferLeads.refetch();
   });
   const mDbLeadStatus = useMDatabase_UpdateValue(db_L("status"));

   return (
      <div className={cx("rightTopC")}>
         <div className={cx("leadNameC")}>
            <div className={cx("leadName")}>
               <SoSDataPoint type="bold-value" value={name} database={db_L("name")} />
            </div>
            <SoSCopyToClipboard value={name!} placement="top-left" />
         </div>
         <div className={cx("btnsTopC")}>
            <b>Status</b>
            <div className={cx(`statusSelect`)}>
               <SoSInputSelect
                  value={statusLabel || ""}
                  options={qLeadStatusesOptions}
                  isMulti={false}
                  onChange={({ value }) => mDbLeadStatus.mutate({ value })}
               />
            </div>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <SoSSwitch
               label="View Comments"
               checked={isViewComment}
               onChange={(b) => {
                  offersDispatch({ overwrite: { isViewComment: b } });
               }}
            />
         </div>
      </div>
   );
};

const NoSelectedLead = () => {
   return (
      <div className={cx("rightBodyC")}>
         <div className={cx("noLeadC")}>Create a lead from the viable properties.</div>
      </div>
   );
};
