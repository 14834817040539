import Timeline, {
   DateHeader,
   ReactCalendarItemRendererProps,
   SidebarHeader,
   TimelineGroupBase,
   TimelineHeaders,
   TimelineItemBase,
   TodayMarker,
} from "react-calendar-timeline";
import moment from "moment";
import styles from "./TransactionTimeLineView.module.scss";
import "./TransactionTimeLineView.scss";
import {
   Transactions_GetTransactions_Transaction,
   useQTrans_Transactions,
} from "../../../hooks/transactions/queries/useQTrans_Transactions";
import classNames from "classnames/bind";
import {
   useSoSOptions_Transaction_ClosingStatus,
   useSoSOptions_Transaction_EMDStatus,
   useSoSOptions_Transaction_FinancingTeam,
   useSoSOptions_Transaction_Inspection,
   useSoSOptions_Transaction_TitleCompany,
} from "../../../hooks/queries/options/useQSoS_Options";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { SHrPopup } from "@simplyhomes/react";
import { TransactionActiveContractModal } from "../TransactionActiveContractModal/TransactionActiveContractModal";

type CustomTimelineGroup = TimelineGroupBase & {
   transaction: Transactions_GetTransactions_Transaction;
};

type CustomTimelineItem = TimelineItemBase<moment.Moment> & {
   color: string;
};

const cx = classNames.bind(styles);

const createTimelineItem = (
   transaction: Transactions_GetTransactions_Transaction,
   statusType: string,
   startDate: string,
   endDate: string,
   status: string,
   color: string
): CustomTimelineItem => {
   return {
      group: transaction.oid,
      id: Math.random(),
      title: `${statusType} - ${status}`,
      start_time: moment(startDate),
      end_time: moment(endDate),
      color,
   };
};

export const TransactionTimeLineView = () => {
   const { transactionsState, transactionsOverwrite } = useTransactionsContext();

   const { showDetailsModal } = transactionsState;

   const qTransactions = useQTrans_Transactions();

   const qEMDStatusOptions = useSoSOptions_Transaction_EMDStatus().data?.options;

   const qInspectionStatusOptions = useSoSOptions_Transaction_Inspection().data?.options || [];

   const qTitleCompanyStatusOptions = useSoSOptions_Transaction_TitleCompany().data?.options || [];

   const qFinancingStatusOptions = useSoSOptions_Transaction_FinancingTeam().data?.options || [];

   const qClosingStatusOptions = useSoSOptions_Transaction_ClosingStatus().data?.options || [];

   const { transactions = [] } = qTransactions;

   const transactionsGroup: CustomTimelineGroup[] = transactions.map((transaction) => {
      return {
         id: transaction.oid,
         title: `${transaction.name}`,
         transaction: transaction,
      };
   });

   const defaultTimeStart = moment().startOf("month");
   const defaultTimeEnd = moment().endOf("month");

   const itemsTest: CustomTimelineItem[] = transactions.flatMap((transaction) => {
      return [
         createTimelineItem(
            transaction,
            "EMD",
            transaction.emd_start_date,
            transaction.emd_end_date,
            transaction.emd_status,
            qEMDStatusOptions?.find((option) => option.label === transaction.emd_status)?.color || ""
         ),
         createTimelineItem(
            transaction,
            "Title",
            transaction.title_start_date,
            transaction.title_end_date,
            transaction.title_status,
            qTitleCompanyStatusOptions.find((option) => option.label === transaction.title_status)?.color || ""
         ),
         ...(transaction.inspection || []).map((inspection) =>
            createTimelineItem(
               transaction,
               "Inspection",
               inspection.start_date,
               inspection.end_date,
               inspection.status,
               qInspectionStatusOptions.find((option) => option.label === inspection.status)?.color || ""
            )
         ),
         createTimelineItem(
            transaction,
            "Financing",
            transaction.financing_start_date,
            transaction.financing_end_date,
            transaction.financing_status,
            qFinancingStatusOptions.find((option) => option.label === transaction.financing_status)?.color || ""
         ),
         createTimelineItem(
            transaction,
            "Closing",
            transaction.closing_start_date,
            transaction.closing_end_date,
            transaction.closing_status,
            qClosingStatusOptions.find((option) => option.label === transaction.closing_status)?.color || ""
         ),
      ];
   });

   const handleGroupClick = (group: CustomTimelineGroup) => {
      transactionsOverwrite({
         oid: group.transaction.oid,
         showDetailsModal: true,
      });
   };

   return (
      <div className={cx("contentC")}>
         <Timeline
            stackItems
            groups={transactionsGroup}
            items={itemsTest}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            className={`${styles.timeline}`}
            minZoom={1000 * 60 * 60 * 24 * 30}
            minResizeWidth={74}
            itemHeightRatio={0.25}
            sidebarWidth={320}
            lineHeight={128}
            groupRenderer={({ group }) => customGroupRenderer({ group, onClick: () => handleGroupClick(group) })}
            itemRenderer={customItemRenderer}
            selected={[]}
         >
            <TimelineHeaders>
               <SidebarHeader>
                  {({ getRootProps }) => {
                     return (
                        <div
                           {...getRootProps({
                              style: {
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 borderRight: "1px solid #bbb",
                              },
                           })}
                        >
                           Active contracts (22)
                        </div>
                     );
                  }}
               </SidebarHeader>
               <DateHeader unit="day" labelFormat="ddd" />
               <DateHeader unit="day" labelFormat="DD" />
            </TimelineHeaders>
            <TodayMarker date={moment().toDate()} children={customTodayMarker} />
         </Timeline>

         <SHrPopup show={showDetailsModal}>
            <TransactionActiveContractModal />
         </SHrPopup>
      </div>
   );
};

const customTodayMarker = ({ styles }: { styles: React.CSSProperties }) => {
   return (
      <div
         style={{
            ...styles,
            border: "3px solid #295BFF",
            position: "relative",
            height: "100%",
         }}
      >
         <div
            style={{
               position: "absolute",
               top: "-3px",
               left: "-13px",
               width: 0,
               height: 0,
               borderLeft: "13px solid transparent",
               borderRight: "13px solid transparent",
               borderTop: "23px solid #295BFF",
            }}
         />
      </div>
   );
};

const customItemRenderer = ({
   item,
   itemContext,
   getItemProps,
   getResizeProps,
}: ReactCalendarItemRendererProps<CustomTimelineItem>) => {
   const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
   return (
      <div
         {...getItemProps({
            style: {
               borderRadius: "8px",
               border: "1px solid #ddd",
               backgroundColor: item.color || "#1a2d3d37",
               color: "#A2ECC2",
               padding: "0px 4px",
               boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            },
         })}
      >
         {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

         <div
            style={{
               height: itemContext.dimensions.height,
               overflow: "hidden",
               paddingLeft: 3,
               textOverflow: "ellipsis",
               whiteSpace: "nowrap",
            }}
         >
            {item.title}
         </div>

         {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
   );
};

const customGroupRenderer = ({ group, onClick }: { group: CustomTimelineGroup; onClick: () => void }) => {
   return (
      <div
         className={cx("groupC")}
         key={group.id}
         style={{
            cursor: "pointer",
         }}
         onClick={onClick}
      >
         <span>
            <b>{group.title}</b>
         </span>
         <div className={cx("infoC")}>
            <div className={cx("info")}>
               <span>Market</span>
               <span>
                  <b>{group.transaction.market_name}</b>
               </span>
            </div>
            <div className={cx("info")}>
               <span>Team Lead</span>
               <span>
                  <b>John Song</b>
               </span>
            </div>
            <div className={cx("info")}>
               <span>Coordinator</span>
               <span>
                  <b>Pittsburgh</b>
               </span>
            </div>
         </div>
      </div>
   );
};
