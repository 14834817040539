import { googleLogout } from "@react-oauth/google";
import { createSearchParams, useNavigate } from "react-router-dom";
import { constants_paths } from "../../constants/path";
import { useAppContext } from "../../contexts/AppContext";
import { useSoSAccessToken } from "./useSoSAccessToken";

export const useSoSLogout = () => {
   const { removeSoSAccessToken } = useSoSAccessToken();

   const { appDispatch } = useAppContext();
   const nav = useNavigate();
   const curPath = window.location.pathname;

   const logOut = () => {
      googleLogout();
      removeSoSAccessToken();
      appDispatch({ overwrite: { sosAccessToken: "" } });
      nav({
         pathname: constants_paths.SIGN_IN,
         search: curPath !== constants_paths.SIGN_IN ? createSearchParams({ redirect: curPath }).toString() : undefined,
      });
   };
   return { logOut };
};
