import classNames from "classnames/bind";
import styles from "./AccountSettingsPage.module.scss";
import SoSVerticalMenu, { TMenuItem } from "../../components/SoSVerticalMenu/SoSVerticalMenu";
import { useState } from "react";
import { MyProfile } from "./MyProfile/MyProfile";
import { NotificationSettings } from "./NotificationSettings/NotificationSettings";
const cx = classNames.bind(styles);
export const AccountSettingsPage = () => {
   const [selectedMenuItem, setSelectedMenuItem] = useState<string>("my-profile");
   return (
      <div className={cx("container")}>
         <div className={cx("menuC")}>
            <SoSVerticalMenu menu={menu} onClick={setSelectedMenuItem} />
         </div>
         <div className={cx("content")}>
            {selectedMenuItem === "my-profile" && <MyProfile />}
            {selectedMenuItem === "notification-settings" && <NotificationSettings />}
         </div>
      </div>
   );
};

const menu: TMenuItem[] = [
   {
      title: "My Profile",
      type: "my-profile",
      icon: "account_settings/person_black.svg",
   },
   {
      title: "Notification Settings",
      type: "notification-settings",
      icon: "account_settings/edit_notifications_black.svg",
   },
];
