import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "./../../../axios/useSosAxios";
import {
   TSoS_Offers_Contact,
   TSoS_Offers_GetLeadSourceProperties_Property,
} from "./useQOffers_GetLeadSourceProperties";
import { useOffersContext } from "../../../../contexts/OffersContext";

export const useQOffers_GetSourceProperties = () => {
   const { sosAxios } = useSosAxios();

   const { sid } = useOffersContext().offersState;

   const query = useQuery({
      queryKey: [`useQOffers_GetSourceProperties`, sid],
      staleTime: 500,
      enabled: !!sid,
      queryFn: () =>
         sosAxios
            .get(`/offers/sources/${sid}/properties`)
            .then<TSoS_Offers_GetSourceProperties_Response>(({ data }) => {
               return data;
            }),
   });
   return query;
};

export type TSoS_Offers_GetSourceProperties_Response = {
   properties: TSoS_Offers_GetLeadSourceProperties_Property[];
   source: SoS_Offers_GetSourceProperties_Source;
};

export type SoS_Offers_GetSourceProperties_Source = {
   sid: number;
   source: string;
   created_at: string;
   contacts: TSoS_Offers_Contact[];
};
