import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { PVi_FilterSortTemplate_Data_Filter } from "../mutates/filterSortTemplates/useMPVi_CreateFilterSortTemplate";
import { usePViContext } from "../../../contexts/PViContext";

export const useQPVi_FilterSortTemplateById = () => {
   const { sosAxios } = useSosAxios();

   const { pviOverwrite } = usePViContext();

   const {
      pviState: { fstid },
   } = usePViContext();

   const query = useQuery({
      enabled: !!fstid,
      queryKey: ["useQPVi_FilterSortTemplateById", fstid],
      queryFn: () =>
         sosAxios
            .get(`/property-viability/properties/filter-sort-templates/${fstid}`)
            .then(({ data }: { data: PVi_GetFilterSortTemplateById_Response }) => {
               if (data.template) {
                  pviOverwrite({
                     fstid: data.template.fstid,
                     filterSortTemplateName: data.template.name,
                     filterSortTemplateData: {
                        ...data.template.data,
                     },
                  });
               }
               return data;
            }),
   });

   return query;
};

export class PVi_GetFilterSortTemplateById_Template_Data {
   filter?: PVi_FilterSortTemplate_Data_Filter;
   sort?: string;
}

export class PVi_GetFilterSortTemplateById_Template {
   fstid!: number;
   name!: string;
   data?: PVi_GetFilterSortTemplateById_Template_Data;
}
export class PVi_GetFilterSortTemplateById_Response {
   template!: PVi_GetFilterSortTemplateById_Template;
}
