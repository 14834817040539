import classNames from "classnames/bind";
import styles from "./PViFilteringAndSortingOption.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { TSoSInputSelect_option } from "../../../../components/common/SoSInputSelect/SoSInputSelect";
import { useState } from "react";

type PViFilteringAndSortingOptionProps = {
   isSelected: boolean;
   value: string | TSoSInputSelect_option;
   onChange: (isSelected: boolean) => void;
};

const cx = classNames.bind(styles);
export const PViFilteringAndSortingOption = ({ isSelected, value, onChange }: PViFilteringAndSortingOptionProps) => {
   const [selected, setSelected] = useState<boolean>(isSelected);

   const isValueString = typeof value === "string";

   const handleToggle = () => {
      const newSelected = !selected;
      setSelected(newSelected);
      onChange(newSelected);
   };

   const handleRemove = (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelected(false);
      onChange(false);
   };

   return (
      <div className={cx("chipC", { isSelected })} onClick={handleToggle}>
         <span>{isValueString ? value : value.label}</span>
         {isSelected && (
            <div className={cx("clearIcon")} onClick={handleRemove}>
               <img src={CF_CDN_URL("/assets/property_viability/close_black.svg")} alt="Clear" />
            </div>
         )}
      </div>
   );
};
