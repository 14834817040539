import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useQSoS_Notifications } from "../queries/useQSoS_Notifications";

export const useMSoS_UpdateNotification = () => {
   const { sosAxios } = useSosAxios();

   const qNotifications = useQSoS_Notifications();

   return useMutation({
      mutationFn: ({ nid, read, starred }: TUpdateNotificationParams) =>
         sosAxios
            .patch(`/notifications/${nid}`, { read, starred })
            .then<TNofs_UpdateNotification_Response>(({ data }) => {
               qNotifications.refetch();
               return data;
            }),
   });
};

export type TUpdateNotificationParams = {
   nid: number;
   read?: boolean;
   starred?: boolean;
};
export type TNofs_UpdateNotification_Response = {
   message: string;
};
