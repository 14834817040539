import { Marker, Polygon } from "@react-google-maps/api";
import { useEffect, useState } from "react";

// const randomColor = () => colors[Math.floor(Math.random() * colors.length)];

type PViSoSPolygonProps = {
   literals: google.maps.LatLngLiteral[][];
   centerText?: string;
   customOptions?: google.maps.PolygonOptions;
   onClick?: () => void;
};

export const PViSoSPolygon = ({ literals, centerText, onClick, customOptions }: PViSoSPolygonProps) => {
   const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(null);

   useEffect(() => {
      if (literals) {
         const bounds = new google.maps.LatLngBounds();
         literals.forEach((polygon) => polygon.forEach((coord) => bounds.extend(coord)));
         setCenter(bounds.getCenter().toJSON());
      }
   }, [literals]);

   return (
      <>
         {literals.map((path, index) => {
            return (
               <Polygon
                  key={index}
                  paths={path}
                  options={{
                     ...customOptions,
                     fillOpacity: 0.35,
                     strokeOpacity: 0.8,
                     strokeWeight: 2,
                     clickable: true,
                  }}
                  {...(onClick && { onClick })}
               />
            );
         })}
         {center && centerText && (
            <Marker
               position={center}
               label={{
                  text: centerText,
                  color: "black",
                  fontSize: "8px",
                  fontWeight: "bold",
               }}
               icon={{
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 0,
               }}
            />
         )}
      </>
   );
};
