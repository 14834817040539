import { useContext, useEffect, useReducer, createContext } from "react";

export class NotificationState {
   constructor(init?: Partial<NotificationState>) {
      if (init) Object.assign(this, init);
   }
   read?: boolean = undefined;
   starred?: boolean = undefined;
   limit: number = 20;
}

type NotificationAction = { overwrite: Partial<NotificationState> };

const NotificationContextDefault = { notificationState: new NotificationState(), notificationDispatch: () => {} };
type NotificationContextType = {
   notificationState: NotificationState;
   notificationDispatch: React.Dispatch<NotificationAction>;
};
const NotificationContext = createContext<NotificationContextType>(NotificationContextDefault);

const notificationReducer = (state: NotificationState, action: NotificationAction): NotificationState => {
   if ("overwrite" in action) return { ...state, ...action.overwrite };
   return state;
};
export type NotificationProviderProps = { children: React.ReactNode; state?: Partial<NotificationState> };
export const NotificationProvider = ({ children, state }: NotificationProviderProps) => {
   const [notificationState, notificationDispatch] = useReducer(notificationReducer, new NotificationState(state));
   useEffect(() => {
      if (state) notificationDispatch({ overwrite: state });
   }, [state]);

   return (
      <NotificationContext.Provider value={{ notificationState, notificationDispatch }}>
         {children}
      </NotificationContext.Provider>
   );
};

export const useNotificationContext = () => useContext(NotificationContext);
