import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "./../../../axios/useSosAxios";
import { useSearchQueryParams_Offers } from "../../../react/useSearchQueryParams";

export const useMOffers_Leads_AddProperty = ({ onSuccess, onError }: TUseMOffers_Leads_AddPropertyProps) => {
   const { sosAxios } = useSosAxios();

   const [lid] = useSearchQueryParams_Offers().lid;

   return useMutation({
      onSuccess,
      onError,
      mutationFn: ({ pids }: TUseMOffers_Leads_AddProperty_Params) =>
         sosAxios
            .post(`/offers/leads/${lid}/properties`, { pids })
            .then<TSoS_Offer_CreateLeadProperties_Response>(({ data }) => data),
   });
};

type TUseMOffers_Leads_AddPropertyProps = {
   onSuccess: (data: TSoS_Offer_CreateLeadProperties_Response) => void;
   onError: (message: string) => void;
};

type TUseMOffers_Leads_AddProperty_Params = {
   pids: string[];
};

type TSoS_Offer_CreateLeadProperties_Response = { message: string };
