import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../axios/useSosAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { constants_paths } from "../../constants/path";
import { useAppContext } from "../../contexts/AppContext";
import { useSoSAccessToken } from "./useSoSAccessToken";

export const useLoginByGoogleOAuthToken = () => {
   const { sosAxios } = useSosAxios();
   const { appOverwrite } = useAppContext();
   const { setSoSAccessToken } = useSoSAccessToken();

   const redirect = useSearchParams()[0].get("redirect");
   const nav = useNavigate();

   return useMutation({
      onSuccess: ({ sosAccessToken }) => {
         if (!sosAccessToken) return;
         const path = redirect || constants_paths.HOME;
         nav(path);
         appOverwrite({ sosAccessToken });
         setSoSAccessToken(sosAccessToken);
      },
      mutationFn: ({ token }: Auth_LoginByGooleOAuthToken_Body) =>
         sosAxios
            .post(`/auth/login-by-google-oauth-token`, { token })
            .then<Auth_LoginByGooleOAuthToken_Response>(({ data }) => data),
   });
};

export class Auth_LoginByGooleOAuthToken_Body {
   token!: string;
}

export class Auth_LoginByGooleOAuthToken_Response {
   sosAccessToken!: string;
}
