import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { TSoS_Offers_Contact } from "./useQOffers_GetLeadSourceProperties";
import { useSearchQueryParams_Offers } from "../../../react/useSearchQueryParams";

export const useQOffers_GetPropertyByIdInLead = () => {
   const { sosAxios } = useSosAxios();
   const { offersState } = useOffersContext();

   const [lid] = useSearchQueryParams_Offers().lid;
   const { pid } = offersState;

   const query = useQuery({
      enabled: !!pid,
      queryKey: [`useQOffers_GetPropertyByIdInLead`, pid],
      staleTime: 500,
      queryFn: () =>
         sosAxios
            .get(`/offers/leads/${lid}/properties/${pid}`)
            .then<TSoS_Offers_GetLeadProperty_Response>((res) => res.data),
   });
   return query;
};

export type TSoS_Offers_GetLeadProperty_Source = {
   sid: number;
   source: string;
   created_at: string;
   contacts: TSoS_Offers_Contact[];
};
export type TSoS_Offers_GetLeadProperty_Property = {
   pid: string;
   address_one_line: string;
   contacts: TSoS_Offers_Contact[];
};

export type TSoS_Offers_GetLeadProperty_Response = {
   property: TSoS_Offers_GetLeadProperty_Property;
   source: TSoS_Offers_GetLeadProperty_Source;
};
