import classNames from "classnames/bind";
import styles from "./OffersViableProperties.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useEffect, useState } from "react";
import { SHrSosButton } from "@simplyhomes/react";
import SoSSlider, { SoSSlider_Item } from "../../../components/SoSSlider/SoSSlider";
import {
   SoS_GetViableProperties_Property,
   useQOffers_GetViableProperties,
} from "../../../hooks/offers/queries/useQOffers_GetViableProperties";
import { useOffersContext } from "../../../contexts/OffersContext";
import { SoSInputTitle } from "../../../components/common/SoSInputTitle/SoSInputTitle";
import useDebounce from "../../../hooks/react/useDebounce";

const cx = classNames.bind(styles);

export const OffersViableProperties = () => {
   const { offersState, offersDispatch } = useOffersContext();
   const { isOpenListViableProperties } = offersState;
   const [isCollapsed, setIsCollapsed] = useState(false);

   const [searchViablePropeties, setSearchViableProperties] = useState<string>("");

   const qOfferViableProperties = useQOffers_GetViableProperties();

   const [viableProperties, setViableProperties] = useState<SoS_GetViableProperties_Property[]>(
      qOfferViableProperties.data?.properties || []
   );

   const searchDebounced = useDebounce(searchViablePropeties, 500);

   useEffect(() => {
      if (qOfferViableProperties.data?.properties) {
         const filteredProperties = qOfferViableProperties.data.properties.filter((property) =>
            property.address_one_line.toLowerCase().includes(searchDebounced.toLowerCase())
         );
         setViableProperties(filteredProperties);
      }
   }, [searchDebounced, qOfferViableProperties.data?.properties]);

   const propertiesSliderData: SoSSlider_Item[] =
      viableProperties.map(({ pid }) => ({
         type: "offer-property-card",
         pid,
      })) || [];

   const propertyLength = qOfferViableProperties.data?.propertyIds.length;

   const handleOpenList = () => {
      offersDispatch({ overwrite: { isOpenListViableProperties: !isOpenListViableProperties } });
      setIsCollapsed(true);
   };

   const handleClickOutSide = () => {
      offersDispatch({ overwrite: { isOpenListViableProperties: false } });
      setIsCollapsed(true);
   };

   return (
      <div className={cx("topC")}>
         <span className={cx("title", "_jr_offersViableProperties")}>
            Viable Properties {`${propertyLength ? `(${propertyLength})` : ""}`}
         </span>
         <SHrSosButton
            type="text"
            buttonProps={{ className: cx("btn", "_jr_offersViablePropertiesExpand"), onClick: handleOpenList }}
         >
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/arrow_downward_black.svg")} alt="" />
            </div>
            Expand List
         </SHrSosButton>
         <div
            className={cx("container", {
               expanded: isOpenListViableProperties,
               collapsed: isCollapsed && !isOpenListViableProperties,
            })}
         >
            <div className={cx("sliderPropertyListC")}>
               <div className={cx("titleC")}>
                  <span className={cx("title")}>
                     Viable Properties {`${propertyLength ? `(${propertyLength})` : ""}`}
                  </span>
                  <SHrSosButton type="text" buttonProps={{ className: cx("btn"), onClick: handleOpenList }}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/arrow_upward_black.svg")} alt="" />
                     </div>
                     Hide List
                  </SHrSosButton>
               </div>
               <div className={cx("inputSearch")}>
                  <SoSInputTitle
                     value={searchViablePropeties}
                     type="text"
                     handleValue={(value) => setSearchViableProperties(value)}
                     icon="search"
                     placeholder="Search an address"
                  />
               </div>
               {!viableProperties.length ? (
                  <div className={cx("noData")}>No viable properties found</div>
               ) : (
                  <SoSSlider items={propertiesSliderData}></SoSSlider>
               )}
            </div>
            <div className={cx(`background`)} onClick={handleClickOutSide}></div>
         </div>
      </div>
   );
};
