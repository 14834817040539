import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useSoSOptions_PVi_FilterSortTemplates } from "../../../queries/options/useQSoS_Options";
import { usePViContext } from "../../../../contexts/PViContext";

export const useMPVi_CreateFilterSortTemplate = () => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const { pviState, pviOverwrite } = usePViContext();

   const { filterSortTemplateData } = pviState;

   const filterSortTemplateOptions = useSoSOptions_PVi_FilterSortTemplates();

   const mutation = useMutation({
      onSuccess: (data) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: { type: "success", message: "Create Filter Sort Template is successful!" },
         });
         filterSortTemplateOptions.refetch();
         pviOverwrite({ fstid: data.fstid });
      },
      onError: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: { type: "error", message: "Create Filter Sort Template is failed!" },
         });
      },
      mutationFn: ({ name }: PVi_CreateFilterSortTemplate_Body) =>
         sosAxios
            .post(`/property-viability/properties/filter-sort-templates`, { name, data: filterSortTemplateData })
            .then(({ data }: { data: PVi_CreateFilterSortTemplate_Response }) => {
               return data;
            }),
   });
   return { ...mutation };
};

export class PVi_FilterSortTemplate_Data_Filter {
   status?: string;
   hasLead?: boolean;
   propertyTypes?: string[];
   states?: string[];
   markets?: string[];
   sources?: string[];
   zips?: string[];
   view?: string;
   offerStatuses?: string[];
   minSourcePrice?: number;
   maxSourcePrice?: number;
}

class PVi_CreateFilterSortTemplate_Data {
   filter?: PVi_FilterSortTemplate_Data_Filter;
   sort?: string;
}

export class PVi_CreateFilterSortTemplate_Body {
   name!: string;
   data?: PVi_CreateFilterSortTemplate_Data;
}

export class PVi_CreateFilterSortTemplate_Response {
   fstid!: number;
}
