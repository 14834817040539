import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useSearchQueryParams_Offers } from "../../../react/useSearchQueryParams";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useQOffers_GetLeads } from "../../queries/leads/useQOffers_GetLeads";
import { useQOffers_GetViableProperties } from "../../queries/useQOffers_GetViableProperties";
import { useQOffers_GetSourceProperties } from "../../queries/leads/useQOffers_GetSourceProperties";
import { useQOffers_Lead } from "../../queries/leads/useQOffers_GetLead";

export const useMOffers_Leads_RemoveProperty = () => {
   const { sosAxios } = useSosAxios();

   const [lid] = useSearchQueryParams_Offers().lid;

   const { toastDispatch } = useToastContext();

   const qOffersGetLeads = useQOffers_GetLeads();

   const qOffersLead = useQOffers_Lead();

   const qOfferViableProperties = useQOffers_GetViableProperties();

   const qOffersGetSourceProperties = useQOffers_GetSourceProperties();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Remove properties is successful!",
            },
         });
         qOffersLead.refetch();
         qOffersGetLeads.refetch();
         qOffersGetSourceProperties.refetch();
         qOfferViableProperties.refetch();
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Remove properties is failed!",
            },
         }),

      mutationFn: ({ pid }: TUseMOffers_Leads_RemoveProperty_Params) =>
         sosAxios
            .delete(`/offers/leads/${lid}/properties/${pid}`)
            .then<TSoS_Offer_DeleteLeadProperties_Response>(({ data }) => data),
   });
};

type TUseMOffers_Leads_RemoveProperty_Params = {
   pid: string;
};

type TSoS_Offer_DeleteLeadProperties_Response = { message: string };
