import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { useSosAxios } from "../../axios/useSosAxios";
import { usePViQueryParams } from "../hooks/usePViQueryParams";
import useDebounce from "../../react/useDebounce";
import { useWebSocket_CatchEvent } from "../../webSocket/useWebSocket_CatchEvent";
import { useQPVi_FilterOptions } from "./useQPVi_FilterOptions";
import { useQPVi_PropertyStatuses } from "./useQPVi_PropertyStatuses";

export const useQPVi_Properties = (p?: TuseQPVi_Properties_Params) => {
   const qFilterOptions = useQPVi_FilterOptions();
   const qPropertyStatues = useQPVi_PropertyStatuses();

   const { noLimit } = p || {};

   const { sosAxios } = useSosAxios();

   const { filters } = usePViQueryParams();

   const dbQ = useDebounce(filters.q, 500);

   const minSourcePrice = useDebounce(filters.minSourcePrice, 500);

   const maxSourcePrice = useDebounce(filters.maxSourcePrice, 500);

   const baseParams = { ...filters, noLimit, q: dbQ, minSourcePrice, maxSourcePrice };

   const query = useInfiniteQuery({
      queryKey: [`QPVi_Properties`, baseParams],
      staleTime: 500,
      queryFn: ({ pageParam: offset }) =>
         sosAxios
            .get(`/property-viability/properties`, { params: { ...baseParams, offset } })
            .then<TUseQPVi_Properties_Data>(({ data }) => data),
      initialPageParam: 0,
      getNextPageParam: ({ metadata: { nextOffset } }) => {
         return nextOffset > 0 && nextOffset >= +filters.limit ? nextOffset : undefined;
      },
   });

   const properties = useMemo(
      () => query.data?.pages.flatMap((page) => page.payload.properties) || [],
      [query.data?.pages]
   );

   useWebSocket_CatchEvent({ room: "refresh:PVi:Properties" }, () => {
      query.refetch();
      qFilterOptions.refetch();
      qPropertyStatues.refetch();
   });

   return { ...query, properties };
};

type TuseQPVi_Properties_Params = {
   noLimit?: boolean;
};

type TUseQPVi_Properties_Data = {
   payload: PVi_GetProperties_Response;
   metadata: { totalRows: number; nextOffset: number };
};

export class PVi_GetProperties_Property {
   pid!: string;
   property_type!: string;
   address_one_line!: string;
   market!: string;
   zip!: string;
   state!: string;
   county!: string;
   city!: string;
   status!: string;
   cap_rate!: number;
   neighborhood!: string;
   geocoding_data!: string;
   geocoding_date!: Date;
   atom_data!: string;
   corelogic_data!: string;
   sqft!: number;
   year_built!: number;
   price!: number;
   max_price_arv_uplift!: number;
   street_view_url_150!: string | null;
   coordinates!: PVi_GetProperties_Property_Coordinate;
   units!: PVi_GetProperties_Property_Unit[];
   lead?: PVi_GetProperties_Property_Lead;
}
class PVi_GetProperties_Property_Offer {
   oid!: number;
   status!: string;
}
class PVi_GetProperties_Property_Lead {
   lid!: number;
   status!: string;
   offer?: PVi_GetProperties_Property_Offer;
}
class PVi_GetProperties_Property_Coordinate {
   lat!: number;
   lng!: number;
}

export class PVi_GetProperties_Property_Unit {
   beds!: number;
   baths!: number;
}

export class PVi_GetProperties_Response {
   properties!: PVi_GetProperties_Property[];
}
