import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const usePVi_ZipcodesBoundaries = (params: PVi_GetZipcodesBoundaries_Params) => {
   const { sosAxios } = useSosAxios();

   const query = useQuery({
      queryKey: ["usePVi_ZipcodesBoundaries", params],
      enabled: !!(params.x1 || params.x2 || params.y1 || params.y2) && params.zoom >= 10,
      queryFn: () =>
         sosAxios
            .get("/property-viability/properties/zipcodes-boundaries", { params })
            .then(({ data }: { data: PVi_GetZipcodesBoundaries_Response }) => data),
   });

   return query;
};

export class PVi_GetZipcodesBoundaries_Zipcode {
   zid!: string;
   blacklisted!: boolean;
   area?: google.maps.LatLngLiteral[][];
}

export class PVi_GetZipcodesBoundaries_Response {
   zipcodes!: PVi_GetZipcodesBoundaries_Zipcode[];
}

export type PVi_GetZipcodesBoundaries_Params = {
   x1: number;
   y1: number;
   x2: number;
   y2: number;
   zoom: number;
};
