import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useToastContext } from "../../../contexts/ToastContext";
import { useQPVi_Properties } from "../queries/useQPVi_Properties";
import { useSearchQueryParams_PVi } from "../../react/useSearchQueryParams";

export const useMPVi_Source_CreateSource = ({ onSuccess }: TUseMPVi_Source_CreateSource) => {
   const setPid = useSearchQueryParams_PVi().pid[1];

   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const qProperty = useQPVi_Properties();

   const mutation = useMutation({
      onSuccess: (data) => {
         if (!data.pids || !data.pids.length) {
            toastDispatch({ type: "ADD_TOAST", payload: { message: "Source already exists!", type: "warning" } });
            return;
         }

         qProperty.refetch();
         toastDispatch({ type: "ADD_TOAST", payload: { message: "Source create sucessfully!", type: "success" } });
         if (data.pids && data.pids.length) setPid(data.pids[0]);
         onSuccess(data);
      },
      mutationFn: (p: CreateSourceParams) =>
         sosAxios.post(`/property-viability/properties/sources`, { ...p }).then((res) => res.data),
   });
   return { ...mutation };
};

export type TUseMPVi_Source_CreateSource = {
   onSuccess: (data: CreateSourceResponse) => void;
};

export type CreateSourceParams_Property_Unit = {
   bed: number;
   bath: number;
};

export type CreateSourceParams_Property = {
   address: string;
   price: number;
   cids: number[];
   units: CreateSourceParams_Property_Unit[];
};

export type CreateSourceParams = {
   source_source: string;
   cids: number[];
   properties: CreateSourceParams_Property[];
};

export type CreateSourceResponse = {
   pids: string[];
};
