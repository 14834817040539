import classNames from "classnames/bind";
import styles from "./TransactionPropertyCard.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useQTrans_Transaction } from "../../../../hooks/transactions/queries/useQTrans_Transaction";
import { usePViContext } from "../../../../contexts/PViContext";
import { useTransactionsContext } from "../../../../contexts/TransactionsContext";
import { useSearchQueryParams_PVi } from "../../../../hooks/react/useSearchQueryParams";

const cx = classNames.bind(styles);

type TTransPropertyCardProps = {
   pid: string;
};

export const TransactionPropertyCard = ({ pid }: TTransPropertyCardProps) => {
   const { pviOverwrite } = usePViContext();

   const setPid = useSearchQueryParams_PVi().pid[1];

   const { transactionsState, transactionsOverwrite } = useTransactionsContext();

   const { pidSelected } = transactionsState;

   const qGetTransaction = useQTrans_Transaction();

   const transaction = qGetTransaction.data?.transaction;

   if (!transaction) return null;

   const { properties } = transaction;

   const property = properties.find((p) => p.pid === pid);

   const handleSelectedProperty = () => {
      transactionsOverwrite({ pidSelected: pidSelected === property?.pid ? "" : property?.pid });
      if (pidSelected !== property?.pid) setPid(property?.pid || "");
   };

   return (
      <div className={cx("container", { selected: pidSelected === property?.pid })}>
         <div className={cx("leftC")} onClick={handleSelectedProperty}>
            <div className={cx("addressImg")}>
               <img src={property?.street_view_url_150} alt="street view" />
            </div>
            <span>{property?.address_one_line}</span>
         </div>

         <div className={cx("img-icon")} onClick={() => setPid(pid)}>
            <img src={CF_CDN_URL("/assets/transaction/open_in_new_black.svg")} alt="" />
         </div>
      </div>
   );
};
