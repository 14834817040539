import styles from "./MapPage.module.scss";
import { useQMap_Properties } from "../../hooks/queries/map/useQMap_Properties";
import { SHrSpinner, useSHrDebounce } from "@simplyhomes/react";
import { SoSInputTitle } from "../../components/common/SoSInputTitle/SoSInputTitle";
import { MapProvider, useMapContext } from "../../contexts/MapContext";
import { useState } from "react";
import { GoogleMapWrapper } from "../../components/GoogleMapWrapper/GoogleMapWrapper";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { SHu_js_currencyFormat, SHu_uuid } from "@simplyhomes/utils";
import { OverlayViewF } from "@react-google-maps/api";
import { useDebounceCallback } from "../../hooks/react/useDebounceCallback";
import { useAppContext } from "../../contexts/AppContext";

const MapPage = () => {
   return (
      <MapProvider>
         <Map />
      </MapProvider>
   );
};

export default MapPage;

const Map = () => {
   const { isGoogleMapsLoaded } = useAppContext().appState;

   const qMapProps = useQMap_Properties();
   const { mapState, mapDispatch } = useMapContext();
   const { filteredPropertiesMap, center, zoom } = mapState;

   const loadingMsg = !isGoogleMapsLoaded
      ? "Loading Google Map"
      : qMapProps.isFetching
      ? "Fetching properties..."
      : null;

   const handleHover = (id: string) => mapDispatch({ overwrite: { hoveredPropertyId: id } });
   const { debouncedCallback: dbHandleHover } = useDebounceCallback(handleHover, 100);

   if (loadingMsg)
      return (
         <div className={`${styles.loadingC}`}>
            <SHrSpinner />
            <span>{loadingMsg}</span>
         </div>
      );
   return (
      <div className={`${styles.containerC}`}>
         <div className={`${styles.mapC}`}>
            <GoogleMapWrapper
               center={center}
               zoom={zoom}
               // onCenterChange={(center) => mapDispatch({ overwrite: { center } })}
               // onZoomChange={(zoom) => mapDispatch({ overwrite: { zoom } })}
            >
               {Object.keys(filteredPropertiesMap).map((id) => (
                  <MapProperty key={id} id={id} onHover={dbHandleHover} />
               ))}
            </GoogleMapWrapper>
         </div>
         <Search />
      </div>
   );
};

const MapProperty = ({ id, onHover }: { id: string; onHover: (id: string) => void }) => {
   const { mapState, mapDispatch } = useMapContext();
   const { filteredPropertiesMap, selectedPropertyId, hoveredPropertyId } = mapState;
   const property = filteredPropertiesMap[id];
   if (!property) return null;
   const { coordinates, address, askingPrice, bathrooms, bedrooms, sqft, unitCount } = property;
   const { x, y } = coordinates;
   const addressUUID = SHu_uuid.Static.StringToUUID(address);
   const streetViewUrl = `https://dyaj6jwzih5dm.cloudfront.net/aio/properties/street-view/${addressUUID}`;
   const isSelected = selectedPropertyId === id;
   const isHovering = hoveredPropertyId === id;
   const showPreview = isHovering || isSelected;
   const handleCopyToClipboard = () => {
      navigator.clipboard.writeText(address);
      alert("Address copied to clipboard");
   };
   return (
      <OverlayViewF mapPaneName="overlayMouseTarget" position={{ lat: x, lng: y }}>
         <div
            className={`${styles.mapPropC}`}
            onClick={() => mapDispatch({ type: "selectProperty", payload: { id } })}
            onMouseEnter={() => onHover(id)}
            onMouseLeave={() => onHover("")}
         >
            {!showPreview ? null : (
               // <div className={`${styles.mapPropMini}`}>
               //    <img src={CF_CDN_URL("/assets/map/map_marker_frame.svg")} alt="" />
               //    <img src={streetViewUrl} alt="streetview" />
               // </div>
               <div className={`${styles.mapPropInfo}`}>
                  <div className={`${styles.mapPropInfoL}`}>
                     <img src={streetViewUrl} alt="streetview" />
                  </div>
                  <div className={`${styles.mapPropInfoR}`}>
                     <div>
                        <img src={CF_CDN_URL("/assets/map/location_neutral.svg")} alt="" />
                        <span>{address}</span>
                        <button type="button" onClick={handleCopyToClipboard}>
                           <img src={CF_CDN_URL("/assets/map/content_copy_neutral.svg")} alt="copy" />
                        </button>
                     </div>
                     <ul>
                        <li>
                           <p>Asking Price</p>
                           <b>{SHu_js_currencyFormat(askingPrice).split(".")[0]}</b>
                        </li>
                        <li>
                           <p>Max Price</p>
                           <b>--</b>
                        </li>
                     </ul>
                     <div>
                        <div>
                           <img src={CF_CDN_URL("/assets/map/square_foot_neutral.svg")} alt="" />
                           <span>{sqft}</span>
                        </div>
                        <div>
                           <img src={CF_CDN_URL("/assets/map/house_neutral.svg")} alt="" />
                           <span>{unitCount}</span>
                        </div>
                        <div>
                           <img src={CF_CDN_URL("/assets/map/bedroom_neutral.svg")} alt="" />
                           <span>{bedrooms}</span>
                        </div>
                        <div>
                           <img src={CF_CDN_URL("/assets/map/bathroom_neutral.svg")} alt="" />
                           <span>{bathrooms}</span>
                        </div>
                     </div>
                  </div>
               </div>
            )}
         </div>
      </OverlayViewF>
   );
};

const Search = () => {
   const { mapState, mapDispatch } = useMapContext();
   const { filteredPropertiesMap, selectedPropertyId } = mapState;
   const [search, setSearch] = useState("");
   const [searchFocused, setSearchFocused] = useState(false);

   const isOpen = selectedPropertyId || searchFocused || search;
   useSHrDebounce({
      value: search,
      delayMs: 1000,
      onDebounced: (search) => mapDispatch({ type: "setSearch", payload: { search } }),
   });

   return (
      <div
         className={`${styles.searchC} ${isOpen && styles.open}`}
         onFocus={() => setSearchFocused(true)}
         onBlur={() => setSearchFocused(false)}
      >
         <div className={`${styles.inputC}`}>
            <SoSInputTitle
               icon="search"
               placeholder="Search"
               value={search}
               handleValue={setSearch}
               type="text"
               labelProps={{}}
            />
         </div>
         <ul>
            {Object.keys(filteredPropertiesMap).map((id) => (
               <SearchProperty key={id} id={id} />
            ))}
         </ul>
      </div>
   );
};

const SearchProperty = ({ id }: { id: string }) => {
   const { mapState, mapDispatch } = useMapContext();
   const { selectedPropertyId } = mapState;
   const property = mapState.filteredPropertiesMap[id];
   if (!property) return null;
   const { address, askingPrice, sqft, unitCount, bedrooms, bathrooms } = property;
   const handleCopyToClipboard = () => {
      navigator.clipboard.writeText(address);
      alert("Address copied to clipboard");
   };
   const isSelected = selectedPropertyId === id;
   return (
      <li
         className={`${styles.searchPropC} ${isSelected && styles.selected}`}
         onClick={() => mapDispatch({ type: "selectProperty", payload: { id } })}
      >
         <div className={`${styles.searchPropTop}`}>
            <img src={CF_CDN_URL("/assets/map/location_neutral.svg")} alt="" />
            <span>{address}</span>
            <button type="button" onClick={handleCopyToClipboard}>
               <img src={CF_CDN_URL("/assets/map/content_copy_neutral.svg")} alt="copy" />
            </button>
         </div>
         <div className={`${styles.searchPropMid}`}>
            <div>
               <p>Asking Price</p>
               <b>{SHu_js_currencyFormat(askingPrice).split(".")[0]}</b>
            </div>
            <div>
               <p>Current Max Price</p>
               <b>--</b>
            </div>
         </div>
         <div className={`${styles.searchPropBot}`}>
            <div>
               <img src={CF_CDN_URL("/assets/map/square_foot_neutral.svg")} alt="" />
               <span>
                  {sqft} ft<sup>2</sup>
               </span>
            </div>
            <div>
               <img src={CF_CDN_URL("/assets/map/house_neutral.svg")} alt="" />
               <span>
                  {unitCount} Unit{unitCount > 1 ? "s" : ""}
               </span>
            </div>
            <div>
               <img src={CF_CDN_URL("/assets/map/bedroom_neutral.svg")} alt="" />
               <span>
                  {bedrooms} Bed{bedrooms > 1 ? "s" : ""}
               </span>
            </div>
            <div>
               <img src={CF_CDN_URL("/assets/map/bathroom_neutral.svg")} alt="" />
               <span>
                  {bathrooms} Bath{bathrooms > 1 ? "s" : ""}
               </span>
            </div>
         </div>
      </li>
   );
};
