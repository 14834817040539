import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../axios/useSosAxios";

export const useSoSGetVersionFromRepo = ({ onNewVersion }: UseSoSGetVersionFromRepoProps) => {
   const { sosAxios } = useSosAxios();

   const query = useQuery({
      queryKey: ["useSoSGetVersionFromRepo"],
      refetchInterval: 300000,
      queryFn: () =>
         sosAxios.get("/versions/repo").then<Versions_GetVersionFromRepo_Response>(({ data }) => {
            if (data.version) {
               onNewVersion();
            }
            return data;
         }),
   });

   return query;
};
export class Versions_GetVersionFromRepo_Response {
   version!: string;
}

type UseSoSGetVersionFromRepoProps = {
   onNewVersion: () => void;
};
