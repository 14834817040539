import classNames from "classnames/bind";
import styles from "./MyProfile.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { useQSOS_Profile } from "../../../hooks/queries/user/useQSOS_Profile";
import { useEffect, useState } from "react";
import { useM_UpdateProfile } from "../../../hooks/profile/mutate/useM_UpdateProfile";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSUploaderWrapper } from "../../../components/SoSUploaderWrapper/SoSUploaderWrapper";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";
import { useMDatabase_UploadImage } from "../../../hooks/database/mutations/useMDatabase_UploadImage";

import { SoSInputTitle } from "../../../components/common/SoSInputTitle/SoSInputTitle";
const cx = classNames.bind(styles);

export const MyProfile = () => {
   const qGerUserProfile = useQSOS_Profile();
   const { mutate: mutateUpdateProfile, isPending } = useM_UpdateProfile();
   const { data: userProfile } = qGerUserProfile;

   const db_User = dbcb("sos_users", [{ column: "uid", id: userProfile?.profile.sub || "" }], () =>
      qGerUserProfile.refetch()
   );

   const mUploadAvatar = useMDatabase_UploadImage(db_User("iu_avatar"));

   const [profile, setProfile] = useState({
      name: userProfile?.profile.name,
      phone: userProfile?.profile.phone,
   });

   useEffect(() => {
      if (userProfile) {
         setProfile({
            name: userProfile.profile.name,
            phone: userProfile.profile.phone,
         });
      }
   }, [userProfile]);

   if (!userProfile) return null;

   const handleUpdateProfile = () => {
      mutateUpdateProfile(profile);
   };

   const handleUploadAvatar = (files: FileList) => {
      mUploadAvatar.mutate({
         images: files,
      });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("personal-infor")}>
            <h3>Personal Information</h3>
            {/* <div className={cx("avt")}>
               <img src={userProfile.profile.avatar || userProfile.profile.picture || ""} alt="" />
            </div> */}

            <SoSUploaderWrapper
               type="images"
               isMultiple={false}
               isLoading={mUploadAvatar.isPending}
               onUpload={(files) => handleUploadAvatar(files)}
            >
               <div className={cx("avt")}>
                  <img src={userProfile.profile.avatar || userProfile.profile.picture || ""} alt="" />
               </div>
            </SoSUploaderWrapper>

            <div className={cx("input")}>
               <SoSInputTitle
                  type={"text"}
                  value={profile.name || ""}
                  title="Profile Name"
                  handleValue={(v) =>
                     setProfile((prev) => ({
                        ...prev,
                        name: v,
                     }))
                  }
               />
            </div>
            <div className={cx("input")}>
               <SoSInputTitle
                  type={"text"}
                  value={userProfile.profile.email}
                  title="Email"
                  inputProps={{ disabled: true }}
               />
            </div>
            <div className={cx("input")}>
               <SoSInputTitle
                  type={"text"}
                  value={profile.phone || ""}
                  handleValue={(v) =>
                     setProfile((prev) => ({
                        ...prev,
                        phone: v,
                     }))
                  }
                  title="Phone"
                  placeholder="(000) 000 0000"
               />
            </div>
            <div className={cx("input")}>
               <SoSInputTitle
                  type={"text"}
                  value={userProfile.profile.roles?.join(", ") || ""}
                  title="Position"
                  inputProps={{ disabled: true }}
                  placeholder="For example: Property Manager"
               />
            </div>
         </div>
         <div className={cx("footer")}>
            <SHrSosButton type="outlined">Cancel</SHrSosButton>
            <SoSConfirmWrapper
               type="default"
               title="Are you sure you want to update your profile?"
               content="This action allows to update your profile."
               onConfirm={handleUpdateProfile}
               onCancel={() => {}}
            >
               <SHrSosButton
                  buttonProps={{
                     disabled: isPending,
                  }}
               >
                  Save Changes
               </SHrSosButton>
            </SoSConfirmWrapper>
         </div>
      </div>
   );
};
