import { useSearchQueryParams } from "../../react/useSearchQueryParams";

export const usePViQueryParams = () => {
   const { params, useSingleQueryParamsState, useMultiQueryParamsState } = useSearchQueryParams({ debounceTime: 500 });
   const [limit] = useSingleQueryParamsState("limit", "50");
   const [q, setQ] = useSingleQueryParamsState("q", "");
   const [status, setStatus] = useSingleQueryParamsState("status", "New");
   const [hasLead, setHasLead] = useSingleQueryParamsState("hasLead", "");
   const [propertyTypes, setPropertyTypes] = useMultiQueryParamsState("propertyTypes", []);
   const [states, setStates] = useMultiQueryParamsState("states", []);
   const [markets, setMarkets] = useMultiQueryParamsState("markets", []);
   const [sources, setSources] = useMultiQueryParamsState("sources", []);
   const [zips, setZips] = useMultiQueryParamsState("zips", []);
   const [view, setView] = useSingleQueryParamsState("view", "Grid View");
   const [offerStatuses, setOfferStatuses] = useMultiQueryParamsState("offerStatuses", []);
   const [minSourcePrice, setMinSourcePrice] = useSingleQueryParamsState("minSourcePrice");
   const [maxSourcePrice, setMaxSourcePrice] = useSingleQueryParamsState("maxSourcePrice");
   const [sort, setSort] = useSingleQueryParamsState("sort", "");

   const removeParams = (paramsName: string[]) => {
      paramsName.forEach((param) => params.delete(param));
   };

   const filters = {
      limit,
      status,
      propertyTypes,
      states,
      markets,
      sources,
      zips,
      q,
      minSourcePrice,
      maxSourcePrice,
      hasLead,
      sort,
      offerStatuses,
   };
   return {
      ...filters,
      removeParams,
      filters,
      setPropertyTypes,
      setStates,
      setMarkets,
      setSources,
      setZips,
      setStatus,
      setQ,
      view,
      setView,
      minSourcePrice,
      setMinSourcePrice,
      maxSourcePrice,
      setMaxSourcePrice,
      setHasLead,
      setSort,
      setOfferStatuses,
   };
};
