import { useOffersContext } from "./../../../../contexts/OffersContext";
import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { TSoS_Offers_Contact } from "./useQOffers_GetLeadSourceProperties";
import { useWebSocket_CatchEvent } from "../../../webSocket/useWebSocket_CatchEvent";
import { useSearchQueryParams_Offers } from "../../../react/useSearchQueryParams";

export const useQOffers_Lead = (p?: useQOffers_Lead_Params) => {
   const { sosAxios } = useSosAxios();
   const { offersDispatch } = useOffersContext();
   const SQP_lid = useSearchQueryParams_Offers().lid[0];

   const LID = Number(p?.lid || SQP_lid);

   const query = useQuery({
      enabled: !!LID,
      queryKey: [`useQOffers_Lead`, LID],
      staleTime: 500,
      queryFn: () =>
         sosAxios.get(`/offers/leads/${LID}`).then(({ data }: { data: TSoS_Offers_GetLeadById_Response }) => {
            offersDispatch({
               overwrite: {
                  leadTermTemplateData: data.lead.terms,
               },
            });
            if (p?.onQuery) p.onQuery(data.lead);
            return {
               lead: {
                  ...data.lead,
                  offersMap: data.lead.offers.reduce((acc, o) => {
                     acc[o.oid] = o;
                     return acc;
                  }, {} as Record<number, TSoS_Offers_GetLeadById_Offer>),
               },
            };
         }),
   });

   useWebSocket_CatchEvent({ room: "refresh:Offers:Lead:", id: LID }, () => query.refetch());

   return query;
};
type useQOffers_Lead_Params = { lid?: number | string; onQuery?: (lead: TSoS_Offers_GetLeadById_Lead) => void };

export type TSoS_Offers_GetLeadById_Property = {
   oid: number;
   pid: string;
   address_one_line: string;
   price: number;
   max_price_arv_uplift: number;
   street_view_url_150: string;
   sid: number;
};

type TSoS_Offers_GetLeadById_Terms = {
   simply: {
      title: string;
      default: string | number;
   }[];
   additional: string[];
};

export type TSoS_Offers_GetLeadById_Offer_Property = {
   oid: number;
   pid: string;
   address_one_line: string;
   asking_price: number;
   max_price_arv_uplift: number;
   street_view_url_150: string;
   target_purchase_price_final: number;
   sqft: number;
   units: { uid: number; beds: number; baths: number }[];
};

export type TSoS_Offers_GetLeadById_Offer = {
   oid: number;
   status: string;
   type: string;
   expire_date: string;
   terms: TSoS_Offers_GetLeadById_Terms;
   properties: TSoS_Offers_GetLeadById_Offer_Property[];
};

type TSoS_Offers_GetLeadById_Source = {
   sid: number;
   source: string;
   created_at: string;
   asking_price: number;
   contacts: TSoS_Offers_Contact[];
};

export type TSoS_Offers_GetLeadById_Lead = {
   sid: number;
   lid: number;
   terms: TSoS_Offers_GetLeadById_Terms;
   status: string;
   name: string;
   created_at: string;
   total_max_price: number;
   total_offer_amount: number;
   cap_rate_cumulative: number;
   arv_uplift_cumulative: number;
   total_all_in: number;
   total_noi: number;
   total_arv: number;
   properties: TSoS_Offers_GetLeadById_Property[];
   offers: TSoS_Offers_GetLeadById_Offer[];
   offersMap: Record<number, TSoS_Offers_GetLeadById_Offer>;
   assignee: string[];
   next_actions: string[];
   source: TSoS_Offers_GetLeadById_Source;
};

export type TSoS_Offers_GetLeadById_Response = { lead: TSoS_Offers_GetLeadById_Lead };
