import { SHrPopup } from "@simplyhomes/react";
import { PViPropertyDetails } from "../PViPropertyDetails/PViPropertyDetails";
import { useSearchQueryParams_PVi } from "../../../hooks/react/useSearchQueryParams";
import { usePViContext } from "../../../contexts/PViContext";
export const PViPropertyDetailsModal = () => {
   const { pviOverwrite } = usePViContext();
   const [pid, setPid] = useSearchQueryParams_PVi().pid;
   const handleShow = (b: boolean) => {
      pviOverwrite({ excludedPropertyIds: [] });
      if (!b) setPid("");
   };
   return (
      <SHrPopup show={!!pid} handleShow={handleShow}>
         <PViPropertyDetails />
      </SHrPopup>
   );
};
