import classNames from "classnames/bind";
import styles from "./OffersAddPropertiesToLeadModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { OffersPropertiesSelection } from "../OffersPropertiesSelection/OffersPropertiesSelection";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useMemo, useState } from "react";
import { useQOffers_Lead } from "../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { useMOffers_Leads_AddProperty } from "../../../hooks/offers/mutates/leads/useMOffers_Leads_AddProperty";
import { useQOffers_GetSourceProperties } from "../../../hooks/offers/queries/leads/useQOffers_GetSourceProperties";
import { useQOffers_GetLeads } from "../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { useQOffers_GetViableProperties } from "../../../hooks/offers/queries/useQOffers_GetViableProperties";
import { useToastContext } from "../../../contexts/ToastContext";
import { SoSInputTitle } from "../../../components/common/SoSInputTitle/SoSInputTitle";
import { formatDate } from "../OffersLeadCard/OffersLeadCard";

const cx = classNames.bind(styles);

const compareProperties = (pre: string[], cur: string[]) => {
   const array2Sorted = cur.slice().sort();
   return (
      pre.length === cur.length &&
      pre
         .slice()
         .sort()
         .every((value, index) => value === array2Sorted[index])
   );
};

export const OffersAddPropertiesToLeadModal = ({ onClose }: TOffersAddPropertiesToLeadModalProps) => {
   const { toastDispatch } = useToastContext();

   const qOffersGetLead = useQOffers_Lead();

   const qOffersLeads = useQOffers_GetLeads();

   const qOffersGetSourceProperties = useQOffers_GetSourceProperties();

   const qOffersGetViableProperties = useQOffers_GetViableProperties();

   const [search, setSearch] = useState("");

   const { mutate, isPending } = useMOffers_Leads_AddProperty({
      onSuccess: ({ message }) => {
         qOffersGetLead.refetch();
         qOffersLeads.refetch();
         qOffersGetSourceProperties.refetch();
         qOffersGetViableProperties.refetch();
         onClose();
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Add properties to lead is successful!",
            },
         });
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Add properties to lead is failed!",
            },
         });
      },
   });
   const { data } = qOffersGetLead;

   const propertiesInLeadId = useMemo(() => {
      return data?.lead.properties.map((p) => p.pid) || [];
   }, [data?.lead.properties]);

   const [selectedProperties, setSelectedProperties] = useState<string[]>(propertiesInLeadId);

   const handleAddPropertiesToLead = () => {
      mutate({ pids: selectedProperties });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/add_box_black.svg")} alt="" />
            </div>
            <span>Adding Property to this Lead</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         <div className={cx("contentC")}>
            <span>You can include multiple properties, please select any that you want to include.</span>

            <div className={cx("input_search")}>
               <SoSInputTitle
                  value={search}
                  type="text"
                  handleValue={setSearch}
                  icon="search"
                  placeholder="Search a contact name, email or phone number"
               />
            </div>

            <div className={cx("sourceInfoC")}>
               <div className={cx("sourceTypeC")}>
                  <span>
                     Source type: <b>{qOffersGetSourceProperties.data?.source.source || "N/A"}</b>
                  </span>
                  <span>
                     Date submitted: <b>{formatDate(qOffersGetSourceProperties.data?.source.created_at || "")}</b>
                  </span>
               </div>

               {qOffersGetSourceProperties.isLoading ? (
                  <div className={cx("loading")}>
                     <SHrSpinner />
                  </div>
               ) : qOffersGetSourceProperties.data?.source.contacts.length === 0 ? (
                  <div className={cx("noContact")}>No source's contact found</div>
               ) : (
                  qOffersGetSourceProperties.data?.source.contacts.map((contact, i) => (
                     <div className={cx("contactC")} key={i}>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/person_black.svg")} alt="" />
                           </div>
                           <span>{contact.name || "Ethan Harrison"}</span>
                        </div>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/email_black.svg")} alt="" />
                           </div>
                           <span>{contact.email}</span>
                        </div>
                        <div className={cx("contactInfo")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/offer/phone_black.svg")} alt="" />
                           </div>
                           <span>{contact.phone_primary || "(000) 000 0000"}</span>
                        </div>
                     </div>
                  ))
               )}
            </div>

            {qOffersGetSourceProperties.data && qOffersGetSourceProperties.data.properties.length > 0 ? (
               qOffersGetSourceProperties.data?.properties.map((item, i) => (
                  <OffersPropertiesSelection
                     type="select"
                     key={i}
                     property={item}
                     value={selectedProperties.includes(item.pid)}
                     disabled={propertiesInLeadId.includes(item.pid)}
                     onSelectProperty={(value) => {
                        if (value) setSelectedProperties([...selectedProperties, item.pid]);
                        else setSelectedProperties(selectedProperties.filter((p) => p !== item.pid));
                     }}
                  />
               ))
            ) : (
               <span>No properties in same source</span>
            )}
         </div>

         <SoSConfirmWrapper
            title="Are you sure you want to apply these properties to this lead?"
            content="This action allows to apply these properties to this lead."
            type="default"
            onConfirm={() => handleAddPropertiesToLead()}
         >
            <div className={cx("btnSave")}>
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn"),
                     disabled: !selectedProperties.length || compareProperties(propertiesInLeadId, selectedProperties),
                  }}
                  loading={isPending}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/offer/save_black.svg")} alt="" />
                  </div>
                  Save
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>
      </div>
   );
};

type TOffersAddPropertiesToLeadModalProps = {
   onClose: () => void;
};
