import classNames from "classnames/bind";
import styles from "./PViSorting.module.scss";
import { PViFilterSelection } from "../PViFiltering/PViFiltering";
import { usePViContext } from "../../../../contexts/PViContext";

const cx = classNames.bind(styles);

export const PViSorting = () => {
   const { pviState, pviOverwrite } = usePViContext();

   const { filterSortTemplateData } = pviState;

   const { sort } = filterSortTemplateData;
   return (
      <div className={cx("container")}>
         <PViFilterSelection
            isMulti={false}
            title="Time"
            options={[
               { value: "time-asc", label: "New to Old" },
               { value: "time-desc", label: "Old to New" },
            ]}
            value={sort || ""}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     sort: op.value,
                  },
               });
            }}
         />

         <PViFilterSelection
            isMulti={false}
            title="Asking Price"
            options={[
               { value: "askingPrice-asc", label: "Low to High" },
               { value: "askingPrice-desc", label: "High to Low" },
            ]}
            value={sort || ""}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     sort: op.value,
                  },
               });
            }}
         />

         <PViFilterSelection
            isMulti={false}
            title="Max Price"
            options={[
               { value: "maxPrice-asc", label: "Low to High" },
               { value: "maxPrice-desc'", label: "High to Low" },
            ]}
            value={sort || ""}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     sort: op.value,
                  },
               });
            }}
         />

         <PViFilterSelection
            isMulti={false}
            title="Delta Max/Asking (Max - Asking)"
            options={[
               { value: "deltaMaxAsking-asc", label: "Low to High" },
               { value: "deltaMaxAsking-desc", label: "High to Low" },
            ]}
            value={sort || ""}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     sort: op.value,
                  },
               });
            }}
         />
      </div>
   );
};
