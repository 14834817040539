import { createContext, useContext, useReducer } from "react";
import { useSoSAccessToken } from "../hooks/auth/useSoSAccessToken";
import { SoS_GetUserProfile } from "../hooks/queries/user/useQSOS_Profile";

class CAppState {
   credential: string = "";
   sosAccessToken: string = "";
   isGoogleMapsLoaded: boolean = false;
   currentUser: SoS_GetUserProfile = {
      email: "",
      name: "",
      picture: "",
      roles: [],
      subscribed_apps: [],
      sub: "",
   };
   isShowVerticalNavigation: boolean = false;
}
const AppContext = createContext({ appState: new CAppState(), appDispatch: (_: TAppAction) => {} });
type TAppAction = { overwrite: TAppOverwrite };
type TAppOverwrite = Partial<CAppState>;
const appReducer = (state: CAppState, action: TAppAction): CAppState => {
   if ("overwrite" in action) return { ...state, ...action.overwrite };
   // switch (action.type) {
   //     default:
   //         break;
   // }
   return { ...state };
};

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
   const { sosAccessToken } = useSoSAccessToken();
   const [appState, appDispatch] = useReducer(appReducer, { ...new CAppState(), sosAccessToken });
   return <AppContext.Provider value={{ appState, appDispatch }}>{children}</AppContext.Provider>;
};
export const useAppContext = () => {
   const context = useContext(AppContext);
   const appOverwrite = (overwrite: Partial<CAppState>) => context.appDispatch({ overwrite });
   return { ...context, appOverwrite };
};
