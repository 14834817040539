import classNames from "classnames/bind";
import styles from "./PViZipCodesMapModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { GoogleMapWrapper } from "../../../components/GoogleMapWrapper/GoogleMapWrapper";
import { useMemo, useRef, useState } from "react";
import { SoSInputTitle } from "../../../components/common/SoSInputTitle/SoSInputTitle";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { PVi_GetZipcodes_Zipcode, usePVi_ZipCodes } from "../../../hooks/property-viability/queries/usePVi_ZipCodes";
import { PViZipCodeCard } from "./PViZipCodeCard/PViZipCodeCard";
import { SHrSpinner } from "@simplyhomes/react";
import useDebounce from "../../../hooks/react/useDebounce";
import { usePVi_ZipcodesBoundaries } from "../../../hooks/property-viability/queries/usePVi_ZipcodesBoundaries";
import { PViSoSPolygon } from "./PViSoSPolygon/PViSoSPolygon";
import { useDebounceCallback } from "../../../hooks/react/useDebounceCallback";
// import InfiniteScroll from "react-infinite-scroller";

const cx = classNames.bind(styles);

export const PViZipCodesMapModal = ({ onClose }: PViZipCodesMapModalProps) => {
   const ref = useRef<HTMLDivElement>(null);

   const [center, setCenter] = useState({ lat: 40.4406248, lng: -79.9958864 });

   const [mapBounds, setMapBounds] = useState({ x1: -79.946288, y1: 40.432234, x2: -79.962348, y2: 40.446986 });

   const [zoom, setZoom] = useState(12);

   const [selectedZip, setSelectedZip] = useState("");

   const [search, setSearch] = useState("");

   const qZipCodes = usePVi_ZipCodes();

   const { debouncedCallback: debouncedSetMapBounds } = useDebounceCallback(setMapBounds, 2000);

   const qZipCodesBoundaries = usePVi_ZipcodesBoundaries({ ...mapBounds, zoom });

   const options = [
      { label: `Blacklisted (${qZipCodes.data?.filter.blacklisted_count})`, value: "blacklisted" },
      { label: `All (${qZipCodes.data?.filter.all})`, value: "all" },
   ];

   const debouncedSearch = useDebounce(search, 200);

   const [selectedOption, setSelectedOption] = useState<string>(options[0].value);

   const zipcodes = useMemo(() => {
      const zips = qZipCodes.data?.zipcodes || [];
      const filtered =
         zips.filter((zip) => {
            const matchesSearch = zip.zid.toLowerCase().startsWith(debouncedSearch.toLowerCase());
            const matchesFilter = selectedOption === "all" || (selectedOption === "blacklisted" && zip.blacklisted);
            return matchesSearch && matchesFilter;
         }) || [];
      if (selectedOption === "all") return filtered.splice(0, 200);
      return filtered;
   }, [debouncedSearch, qZipCodes.data?.zipcodes, selectedOption]);

   // const zipcodes = useMemo(() => {
   //    const zips = qZipCodes.data?.zipcodes || [];
   //    const filtered =
   //       zips.filter((zip) => {
   //          const matchesSearch = zip.zid.toLowerCase().startsWith(debouncedSearch.toLowerCase());
   //          const matchesFilter = selectedOption === "all" || (selectedOption === "blacklisted" && zip.blacklisted);
   //          return matchesSearch && matchesFilter;
   //       }) || [];
   //    return filtered;
   // }, [debouncedSearch, qZipCodes.data?.zipcodes, selectedOption]);

   // const loadMore = () => {
   //    if (page * 20 >= zipcodes.length) {
   //       setHasMore(false);
   //       return;
   //    }
   //    setPage((prevPage) => prevPage + 1);
   // };

   const handleClickToZip = ({ x, y, zid }: Pick<PVi_GetZipcodes_Zipcode, "x" | "y" | "zid">) => {
      if (!x || !y) return;
      setSelectedZip(zid);
      setCenter({ lat: y, lng: x });
      setZoom(12);
   };

   const handleLoadMap = (map: google.maps.Map) => {
      map.addListener("bounds_changed", () => {
         const bounds = map.getBounds();
         if (bounds) {
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            debouncedSetMapBounds({
               x1: sw.lng(),
               y1: sw.lat(),
               x2: ne.lng(),
               y2: ne.lat(),
            });
         }
      });
      map.addListener("zoom_changed", () => {
         const newZoom = map.getZoom();
         if (newZoom) setZoom(newZoom);
      });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <span>Zip Codes Management </span>
            {qZipCodesBoundaries.isLoading && (
               <div className={cx("loading")}>
                  <SHrSpinner /> <span>Loading Zipcodes Map...</span>
               </div>
            )}
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("contentC")}>
            <div className={cx(`mapC`)} ref={ref}>
               <GoogleMapWrapper center={center} zoom={zoom} mapProps={{ onLoad: handleLoadMap }}>
                  {qZipCodesBoundaries.data?.zipcodes.map((z, i) => {
                     return (
                        <PViSoSPolygon
                           key={i}
                           literals={z.area || []}
                           centerText={z.zid || ""}
                           onClick={() => setSelectedZip(z.zid)}
                           customOptions={{
                              fillColor: z.blacklisted ? "black" : "green",
                              strokeColor: selectedZip === z.zid ? "red" : "#8e8f8f",
                           }}
                        />
                     );
                  })}
               </GoogleMapWrapper>
            </div>

            <div className={cx("zipCodeListC")}>
               <div className={cx("input_search")}>
                  <SoSInputTitle
                     value={search}
                     type="text"
                     handleValue={setSearch}
                     icon="search"
                     placeholder="Zip Code that starts with..."
                  />
               </div>

               <div className={cx("filter")}>
                  {qZipCodes.isLoading ? (
                     <div className={cx("loadingFilter")}>
                        Filter
                        <SHrSpinner />
                     </div>
                  ) : (
                     <SoSInputSelect
                        options={options}
                        value={selectedOption}
                        onChange={({ value }) => setSelectedOption(value)}
                        isMulti={false}
                        className={cx("filter-dropdown")}
                     />
                  )}
               </div>

               <div className={cx("list")}>
                  {qZipCodes.isLoading ? (
                     <div className={cx("loading")}>
                        <SHrSpinner /> <span>Loading Zip Codes...</span>
                     </div>
                  ) : !zipcodes.length ? (
                     <div className={cx("noData")}>No zip codes found</div>
                  ) : (
                     zipcodes.map((zip, index) => (
                        <PViZipCodeCard
                           key={index + zip.zid}
                           zid={zip.zid}
                           onClick={() => handleClickToZip(zip)}
                           isSelected={zip.zid === selectedZip}
                        />
                     ))
                     // <InfiniteScroll
                     //    pageStart={0}
                     //    loadMore={loadMore}
                     //    hasMore={hasMore}
                     //    loader={
                     //       <div className={cx("loading")} key={0}>
                     //          <SHrSpinner /> <span>Loading more Zip Codes...</span>
                     //       </div>
                     //    }
                     //    useWindow={false}
                     // >
                     //    {zipcodes.map((zip, index) => (
                     //       <PViZipCodeCard
                     //          key={index + zip.zid}
                     //          zid={zip.zid}
                     //          onClick={() => handleClickToZip(zip)}
                     //          isSelected={zip.zid === selectedZip}
                     //       />
                     //    ))}
                     // </InfiniteScroll>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

type PViZipCodesMapModalProps = {
   onClose: () => void;
};
