import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "../../../contexts/AppContext";
import { useSosAxios } from "../../axios/useSosAxios";
import { useToastContext } from "../../../contexts/ToastContext";
import { useQSOS_Profile } from "../../queries/user/useQSOS_Profile";

export const useM_UpdateProfile = () => {
   const { sosAxios } = useSosAxios();
   const { appState } = useAppContext();

   const qGetProfile = useQSOS_Profile();

   const { toastDispatch } = useToastContext();

   const { currentUser } = appState;

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Update profile is successful!",
            },
         });
         qGetProfile.refetch();
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Update profile is failed!",
            },
         });
      },
      mutationFn: (body: SoS_UpdateProfile_Body_Dto) =>
         sosAxios.patch(`profile/${currentUser.sub}`, { ...body }).then<SoS_UpdateProfile_Response>(({ data }) => data),
   });
};

export class SoS_UpdateProfile_Body_Dto {
   notify_to_slack?: boolean;
   notify_to_email?: boolean;
   name?: string;
   phone?: string;
   slack_email?: string;
}

export class SoS_UpdateProfile_Response {
   message!: string;
}
