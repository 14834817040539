import classNames from "classnames/bind";
import styles from "./NotificationSettings.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { useQSOS_Profile } from "../../../hooks/queries/user/useQSOS_Profile";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useEffect, useState } from "react";
import { SoSSwitch } from "../../../components/common/SoSSwitch/SoSSwitch";
import { SoS_UpdateProfile_Body_Dto, useM_UpdateProfile } from "../../../hooks/profile/mutate/useM_UpdateProfile";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSInputTitle } from "../../../components/common/SoSInputTitle/SoSInputTitle";

const cx = classNames.bind(styles);

// const settings: Setting[] = [
//    {
//       title: "Connect to Slack",
//       description: "Use your Slack account to get latest notifications from SOS",
//       icon: "slack_icon.svg",
//       titleInput: "Slack Email",
//       switchLabel: "Turn on notifications on Slack",
//       isTurnOn: false,
//       expanded: false,
//    },
//    {
//       title: "Connect to Email",
//       description: "Send latest updates to your email",
//       icon: "email_black.svg",
//       expanded: false,
//       titleInput: "Email",
//       switchLabel: "Notify me via email for every updates",
//       isTurnOn: true,
//    },
// ];

export const NotificationSettings = () => {
   const [expandedNofSlack, setExpandedNofSlack] = useState(false);
   const [expandedNofEmail, setExpandedNofEmail] = useState(false);

   const qGerUserProfile = useQSOS_Profile();

   const { mutate: mutateUpdateProfile, isPending } = useM_UpdateProfile();

   const { data: userProfile } = qGerUserProfile;

   const [settings, setSettings] = useState<SoS_UpdateProfile_Body_Dto>({
      notify_to_slack: userProfile?.settings.notifyToSlack,
      notify_to_email: userProfile?.settings.notifyToEmail,
      slack_email: userProfile?.profile.slack_email || userProfile?.profile.email,
   });

   useEffect(() => {
      if (userProfile) {
         setSettings({
            notify_to_slack: userProfile.settings.notifyToSlack,
            notify_to_email: userProfile.settings.notifyToEmail,
            slack_email: userProfile.profile.slack_email || userProfile.profile.email,
         });
      }
   }, [userProfile]);

   if (!userProfile) return null;

   const handleUpdateProfile = () => {
      mutateUpdateProfile(settings);
   };

   return (
      <div className={cx("container")}>
         <div className={cx("notificationC")}>
            <div className={cx("topC")}>
               <div className={cx("iconC")}>
                  <img src={CF_CDN_URL(`/assets/account_settings/slack_icon.svg`)} alt="slack" />
               </div>
               <div className={cx("textC")}>
                  <div>
                     <b>Connect to Slack</b>
                  </div>
                  <div>Use your Slack account to get latest notifications from SOS</div>
               </div>
               <div className={cx("expandIconC")} onClick={() => setExpandedNofSlack(!expandedNofSlack)}>
                  <img
                     src={CF_CDN_URL(
                        `/assets/account_settings/${
                           expandedNofSlack ? "expand_more_black.svg" : "chevron_right_black.svg"
                        }`
                     )}
                     alt="slack"
                  />
               </div>
            </div>

            {expandedNofSlack && (
               <div className={cx("expanded")}>
                  <div className={cx("inputC")}>
                     <SoSInputTitle
                        isBoldTitle={false}
                        title={"Slack Email"}
                        type="text"
                        value={settings.slack_email || ""}
                        handleValue={(value) => {
                           setSettings((prev) => ({ ...prev, slack_email: value }));
                        }}
                        placeholder="example@company.com"
                     />
                  </div>
                  <div className={cx("switchC")}>
                     <SoSSwitch
                        checked={!!settings.notify_to_slack}
                        onChange={(b) => {
                           setSettings((prev) => ({ ...prev, notify_to_slack: b }));
                        }}
                        label={"Turn on notifications on Slack"}
                     />
                  </div>
               </div>
            )}
         </div>
         <hr />
         <div className={cx("notificationC")}>
            <div className={cx("topC")}>
               <div className={cx("iconC")}>
                  <img src={CF_CDN_URL(`/assets/account_settings/email_black.svg`)} alt="slack" />
               </div>
               <div className={cx("textC")}>
                  <div>
                     <b>Connect to Email</b>
                  </div>
                  <div>Send latest updates to your email</div>
               </div>
               <div className={cx("expandIconC")} onClick={() => setExpandedNofEmail(!expandedNofEmail)}>
                  <img
                     src={CF_CDN_URL(
                        `/assets/account_settings/${
                           expandedNofEmail ? "expand_more_black.svg" : "chevron_right_black.svg"
                        }`
                     )}
                     alt="slack"
                  />
               </div>
            </div>

            {expandedNofEmail && (
               <div className={cx("expanded")}>
                  <div className={cx("inputC")}>
                     <SoSInputTitle
                        isBoldTitle={false}
                        title={"Email"}
                        type="text"
                        value={userProfile.profile.email}
                        handleValue={() => {}}
                        inputProps={{
                           disabled: true,
                        }}
                     />
                  </div>
                  <div className={cx("switchC")}>
                     <SoSSwitch
                        checked={!!settings.notify_to_email}
                        onChange={(b) => {
                           setSettings((prev) => ({ ...prev, notify_to_email: b }));
                        }}
                        label="Notify me via email for every updates"
                     />
                  </div>
               </div>
            )}
         </div>
         <div className={cx("footer")}>
            <SHrSosButton type="outlined">Cancel</SHrSosButton>
            <SoSConfirmWrapper
               type="default"
               title="Are you sure you want to update your profile?"
               content="This action allows to update your profile."
               onConfirm={handleUpdateProfile}
               onCancel={() => {}}
            >
               <SHrSosButton
                  buttonProps={{
                     disabled: isPending,
                  }}
               >
                  Save Changes
               </SHrSosButton>
            </SoSConfirmWrapper>
         </div>
      </div>
   );
};
