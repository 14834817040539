import { SHrSpinner } from "@simplyhomes/react";
import styles from "./PViMapView.module.scss";
import { GoogleMapWrapper } from "../../../components/GoogleMapWrapper/GoogleMapWrapper";
import { SoSMapPropertyMarker } from "../../../components/common/SoSMapPropertyMarker/SoSMapPropertyMarker";
import { useMemo, useState } from "react";
import {
   PVi_GetProperties_Property,
   useQPVi_Properties,
} from "../../../hooks/property-viability/queries/useQPVi_Properties";
import { useSearchQueryParams_PVi } from "../../../hooks/react/useSearchQueryParams";
export const PViMapView = () => {
   const setPid = useSearchQueryParams_PVi().pid[1];

   const qPVi_Properties = useQPVi_Properties({ noLimit: true });

   const { properties = [] } = qPVi_Properties;

   const propMarkers = useMemo(
      () =>
         properties.map((p) => ({
            ...p,
            address: p.address_one_line,
            bedrooms: p.units?.reduce((acc, u) => acc + u.beds, 0),
            bathrooms: p.units?.reduce((acc, u) => acc + u.baths, 0),
            unitCount: p.units?.length,
            askingPrice: p.price,
            sqft: p.sqft || 0,
            streetViewUrl: p.street_view_url_150 || "",
         })),
      [properties]
   );
   const [center, setCenter] = useState({ lat: 40.098844, lng: -95.8959109 });
   const handleAddressClick = ({ coordinates, pid }: PVi_GetProperties_Property) => {
      setCenter(coordinates);
      setPid(pid);
   };

   if (qPVi_Properties.isFetching)
      return (
         <article className={`${styles.contentC} ${styles.loading}`}>
            <SHrSpinner />
            <span>Loading Properties</span>
         </article>
      );
   return (
      <article className={`${styles.contentC}`}>
         <GoogleMapWrapper center={center} zoom={5}>
            {propMarkers.map((p) => (
               <SoSMapPropertyMarker key={p.pid} property={p} onAddressClick={() => handleAddressClick(p)} />
            ))}
         </GoogleMapWrapper>
      </article>
   );
};
