import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useQPVi_Property } from "../../queries/useQPVi_Property";
import { useSearchQueryParams_PVi } from "../../../react/useSearchQueryParams";

export const useMPVi_Property_RentometerRefresh = () => {
   const { toastDispatch } = useToastContext();

   const [pid] = useSearchQueryParams_PVi().pid;

   const { sosAxios } = useSosAxios();
   const qPVi_Property = useQPVi_Property();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: { type: "success", message: "Reset Rentometer is successful!" },
         });
         qPVi_Property.refetch();
      },
      onError: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: { type: "error", message: "Reset Rentometer is failed!" },
         });
      },
      mutationFn: () =>
         sosAxios
            .get(`/property-viability/properties/${pid}/rentometer-refresh`)
            .then<PVi_UseMPVi_Property_RentometerRefresh_Response>(({ data }) => data),
   });
};

export type PVi_UseMPVi_Property_RentometerRefresh_Response = {
   message: string;
};
