import { useSearchParams } from "react-router-dom";
import { useDebounceCallback } from "./useDebounceCallback";

type UseSearchQueryParams_Params = {
   debounceTime?: number;
};
export const useSearchQueryParams = (p?: UseSearchQueryParams_Params) => {
   const { debounceTime = 0 } = p || {};
   const [params, setSearchParams] = useSearchParams();
   const { debouncedCallback: dbSetSearchParams } = useDebounceCallback(
      (params: URLSearchParams) => setSearchParams(params),
      debounceTime
   );
   const useSingleQueryParamsState = (paramString: string, defaultValue?: string) => {
      const state = params.get(paramString) || defaultValue || "";
      const setState = (s: string | number) => {
         params.set(paramString, s.toString());
         if (!s) params.delete(paramString);
         dbSetSearchParams(params);
      };
      return [state, setState] as const;
   };
   const useMultiQueryParamsState = (paramString: string, defaultValue?: string[]) => {
      const state = params.getAll(paramString) || defaultValue || [];
      const setState = (s: string[]) => {
         params.delete(paramString);
         s.forEach((v) => params.append(paramString, v));
         dbSetSearchParams(params);
      };
      return [state, setState] as const;
   };

   return { params, setSearchParams, useSingleQueryParamsState, useMultiQueryParamsState };
};

export const useSearchQueryParams_Convo = () => {
   const { useSingleQueryParamsState } = useSearchQueryParams();
   const tid = useSingleQueryParamsState("convo_tid");
   return { tid };
};

export const useSearchQueryParams_PVi = () => {
   const { useSingleQueryParamsState } = useSearchQueryParams();
   const pid = useSingleQueryParamsState("pvi_pid");
   return { pid };
};

export const useSearchQueryParams_Offers = () => {
   const { useSingleQueryParamsState } = useSearchQueryParams();
   const lid = useSingleQueryParamsState("offers_lid");
   return { lid };
};
