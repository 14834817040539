import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { SoS_GetTermTemplateById_TermTemplate_Data_Field } from "../../queries/termTemplate/useQOffers_GetTermTemplateById";
import { useSearchQueryParams_Offers } from "../../../react/useSearchQueryParams";

export const useMOffers_CreateOffer = ({ onSuccess, onError }: TUseMOffers_CreateOffer) => {
   const { sosAxios } = useSosAxios();
   const [lid] = useSearchQueryParams_Offers().lid;

   return useMutation({
      onSuccess,
      onError,
      mutationFn: (createOfferProps: TSoS_Offers_CreateOffer_Props) =>
         sosAxios
            .post(`/offers/leads/${lid}/offers`, createOfferProps)
            .then<TSoS_Offers_CreateOffer_Response>(({ data }) => data),
   });
};

type TUseMOffers_CreateOffer = {
   onSuccess: (data: TSoS_Offers_CreateOffer_Response) => void;
   onError: (message: string) => void;
};

type SoS_Offers_CreateOffer_Terms = SoS_GetTermTemplateById_TermTemplate_Data_Field;

type TSoS_Offers_CreateOffer_Props = {
   expire_date: string;
   type: string;
   pids: string[];
   terms: SoS_Offers_CreateOffer_Terms;
};

type TSoS_Offers_CreateOffer_Offer = {
   oid: number;
};

type TSoS_Offers_CreateOffer_Response = { offer: TSoS_Offers_CreateOffer_Offer };
