import classNames from "classnames/bind";
import styles from "./SoSRefreshModal.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";

const cx = classNames.bind(styles);

export const SoSRefreshModal = ({ onClose }: SoSRefreshModalProps) => {
   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <span>There is a newer version available!</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         <div className={cx("modalBtns")}>
            <SHrSosButton
               buttonProps={{
                  onClick: () => {
                     window.location.reload();
                  },
               }}
            >
               Refresh
            </SHrSosButton>
            <SHrSosButton
               type="outlined"
               buttonProps={{
                  onClick: () => {
                     onClose();
                  },
               }}
            >
               Later
            </SHrSosButton>
         </div>
      </div>
   );
};

type SoSRefreshModalProps = {
   onClose: () => void;
};
