import classNames from "classnames/bind";
import styles from "./PViFiltering.module.scss";
import { PViFilteringAndSortingOption } from "../PViFilteringAndSortingOption/PViFilteringAndSortingOption";
import { useQPVi_FilterOptions } from "../../../../hooks/property-viability/queries/useQPVi_FilterOptions";
import { TSoSInputSelect_option } from "../../../../components/common/SoSInputSelect/SoSInputSelect";
import { usePViContext } from "../../../../contexts/PViContext";
import SoSFilter from "../../../../components/common/SoSFilter/SoSFilter";
import { SoSInputTitle } from "../../../../components/common/SoSInputTitle/SoSInputTitle";
import { useSoSOptions_Offers_OfferStatuses } from "../../../../hooks/queries/options/useQSoS_Options";

const cx = classNames.bind(styles);

const hasLeadOption = [
   { value: "true", label: "Has Lead" },
   { value: "false", label: "No Lead" },
];

const strToBool = (value: string) => {
   return value?.toLowerCase() === "true";
};

export const PViFiltering = () => {
   const { pviState, pviOverwrite } = usePViContext();

   const { filterSortTemplateData } = pviState;

   const { filter } = filterSortTemplateData;

   const filterOptions = useQPVi_FilterOptions().data;

   const qOpOfferStatus = useSoSOptions_Offers_OfferStatuses();

   const offerStatus = qOpOfferStatus.data?.options || [];

   return (
      <div className={cx("container")}>
         <PViFilterSelection
            isMulti
            title="State"
            options={filterOptions?.states || []}
            value={filter?.states || []}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     filter: {
                        ...filter,
                        states: [...op.map((state) => state.value)],
                     },
                  },
               });
            }}
         />
         <PViFilterSelection
            isMulti
            title="Market"
            options={filterOptions?.markets || []}
            value={filter?.markets || []}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     filter: {
                        ...filter,
                        markets: [...op.map((market) => market.value)],
                     },
                  },
               });
            }}
         />
         <div className={cx("zipCodeSelectionC")}>
            <span>
               <b>ZIP Code</b>
            </span>
            <div className={cx("zipDropdownC")}>
               <SoSFilter
                  type="select"
                  isMulti={true}
                  value={filter?.zips || []}
                  onChange={(vs) => {
                     pviOverwrite({
                        filterSortTemplateData: {
                           ...filterSortTemplateData,
                           filter: {
                              ...filter,
                              zips: vs.map((o) => o.value),
                           },
                        },
                     });
                  }}
                  options={filterOptions?.zips || []}
               />
            </div>

            <PViFilterSelection
               isMulti
               value={filter?.zips || []}
               options={filter?.zips || []}
               onChange={(vs) => {
                  pviOverwrite({
                     filterSortTemplateData: {
                        ...filterSortTemplateData,
                        filter: {
                           ...filter,
                           zips: vs.map((o) => o.value),
                        },
                     },
                  });
               }}
            />
         </div>
         <PViFilterSelection
            isMulti
            title="Property Type"
            options={filterOptions?.propertyTypes || []}
            value={filter?.propertyTypes || []}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     filter: {
                        ...filter,
                        propertyTypes: [...op.map((propertyType) => propertyType.value)],
                     },
                  },
               });
            }}
         />
         <PViFilterSelection
            isMulti
            title="Source"
            options={filterOptions?.sources || []}
            value={filter?.sources || []}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     filter: {
                        ...filter,
                        sources: [...op.map((source) => source.value)],
                     },
                  },
               });
            }}
         />
         <div className={cx("askingPriceC")}>
            <span>
               <b>Asking Price</b>
            </span>
            <div className={cx("bottom")}>
               <div className={cx("priceC")}>
                  <SoSInputTitle
                     inputProps={{
                        min: 0,
                     }}
                     type={"number"}
                     value={filter?.minSourcePrice || ""}
                     format="currency"
                     placeholder="Min Price"
                     handleValue={(v) => {
                        pviOverwrite({
                           filterSortTemplateData: {
                              ...filterSortTemplateData,
                              filter: {
                                 ...filter,
                                 minSourcePrice: Number(v),
                              },
                           },
                        });
                     }}
                  />
               </div>
               <div className={cx("priceC")}>
                  <SoSInputTitle
                     inputProps={{
                        min: 0,
                     }}
                     type={"number"}
                     value={filter?.maxSourcePrice || ""}
                     format="currency"
                     placeholder="Max Price"
                     handleValue={(v) => {
                        pviOverwrite({
                           filterSortTemplateData: {
                              ...filterSortTemplateData,
                              filter: {
                                 ...filter,
                                 maxSourcePrice: Number(v),
                              },
                           },
                        });
                     }}
                  />
               </div>
            </div>
         </div>
         <PViFilterSelection
            isMulti={false}
            title="Lead"
            options={hasLeadOption}
            value={filter?.hasLead !== undefined ? String(filter.hasLead) : ""}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     filter: {
                        ...filter,
                        hasLead: op.value === String(filter?.hasLead) ? undefined : strToBool(op.value),
                     },
                  },
               });
            }}
         />
         <PViFilterSelection
            isMulti
            title="Offer Status"
            options={offerStatus || []}
            value={filter?.offerStatuses || []}
            onChange={(op) => {
               pviOverwrite({
                  filterSortTemplateData: {
                     ...filterSortTemplateData,
                     filter: {
                        ...filter,
                        offerStatuses: [...op.map((offerStatus) => offerStatus.value)],
                     },
                  },
               });
            }}
         />
      </div>
   );
};

type PViFilterSelectionCommonProps = {
   title?: string;
   options: string[] | TSoSInputSelect_option[];
};

type PViFilterSelectionProps =
   | (PViFilterSelectionCommonProps & {
        isMulti: true;
        value: string[];
        onChange: (value: TSoSInputSelect_option[]) => void;
     })
   | (PViFilterSelectionCommonProps & {
        isMulti: false;
        onChange: (value: TSoSInputSelect_option) => void;
        value: string;
     });

export const PViFilterSelection = ({ options, title, onChange, value, isMulti }: PViFilterSelectionProps) => {
   const handleOptionChange = (isSelected: boolean, option: string | TSoSInputSelect_option) => {
      if (isMulti) {
         const updatedValues = isSelected
            ? [...value, option]
            : value.filter((v) =>
                 typeof option === "string" ? v !== option : v !== (option as TSoSInputSelect_option).value
              );

         const updatedValuesTransformed = updatedValues.map((v) =>
            typeof v === "string" ? ({ label: v, value: v } as TSoSInputSelect_option) : v
         );

         onChange(updatedValuesTransformed);
      } else {
         const updatedValue = isSelected
            ? typeof option === "string"
               ? ({ label: option, value: option } as TSoSInputSelect_option)
               : option
            : ({} as TSoSInputSelect_option);

         onChange(updatedValue);
      }
   };

   const isOptionSelected = (option: string | TSoSInputSelect_option) => {
      return (isMulti ? value : [value]).some((v) =>
         typeof option === "string" ? v === option : v === (option as TSoSInputSelect_option).value
      );
   };

   return (
      <div className={cx("wrapper")}>
         <span>
            <b>{title}</b>
         </span>

         <div className={cx("options")}>
            {options.map((option, i) => {
               const isSelected = isOptionSelected(option);

               return (
                  <PViFilteringAndSortingOption
                     value={option}
                     key={i}
                     isSelected={isSelected}
                     onChange={(isSelected) => handleOptionChange(isSelected, option)}
                  />
               );
            })}
         </div>
      </div>
   );
};
