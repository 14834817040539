import { useState } from "react";
import styles from "./SoSVerticalMenu.module.scss";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import classNames from "classnames/bind";
import { TAppCategory } from "../../constants/apps";

const cx = classNames.bind(styles);

export type TMenuItem = {
   title: string;
   type: TAppCategory | string;
   icon: string;
};

type MenuProps = {
   menu: TMenuItem[];
   onClick: (type: TAppCategory | string) => void;
};

const SoSVerticalMenu = ({ menu, onClick }: MenuProps) => {
   const [selected, setSelected] = useState(menu[0].type);

   return (
      <div className={cx("menuList")}>
         <ul>
            {menu.map((item, index) => (
               <li key={index} onClick={() => setSelected(item.type)}>
                  <button
                     type="button"
                     onClick={() => onClick(item.type)}
                     className={cx({ active: item.type === selected })}
                  >
                     <img src={CF_CDN_URL(`/assets/${item.icon}`)} alt={item.title} />
                     <span>{item.title}</span>
                  </button>
               </li>
            ))}
         </ul>
      </div>
   );
};

export default SoSVerticalMenu;
