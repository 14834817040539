import { TableColumn } from "react-data-table-component";
import { PVi_GetProperties_Property } from "../../../hooks/property-viability/queries/useQPVi_Properties";
import { SHu_js_currencyFormat, SHu_js_percentageFormat } from "@simplyhomes/utils";

export const allPropertiesColumn: TableColumn<PVi_GetProperties_Property>[] = [
   { name: <strong>Street Address</strong>, selector: (property) => property.address_one_line },
   { name: <strong>Neighborhood</strong>, selector: (property) => property.neighborhood },
   { name: <strong>Market</strong>, selector: (property) => property.market },
   { name: <strong>Type</strong>, selector: (property) => property.property_type },
   {
      name: <strong>Total Beds</strong>,
      width: "100px",
      selector: (property) => property?.units?.map((unit) => unit.beds).reduce((acc, value) => acc + value, 0),
   },
   { name: <strong>Asking Price</strong>, selector: (property) => SHu_js_currencyFormat(property.price).split(".")[0] },
   { name: <strong>Square Feet</strong>, selector: (property) => property.sqft },
   { name: <strong>Max Offer Amount</strong>, selector: (property) => "" },
   { name: <strong>Cap Rate</strong>, selector: (property) => SHu_js_percentageFormat(property.cap_rate) },
   { name: <strong>Lead history</strong>, selector: (property) => "" },
   { name: <strong>Year Built</strong>, selector: (property) => property.year_built },
];
