import { useSearchQueryParams } from "../../react/useSearchQueryParams";

export const useTransQueryParams = () => {
   const { useSingleQueryParamsState } = useSearchQueryParams();
   const [limit] = useSingleQueryParamsState("limit", "50");

   const filters = { limit };

   return {
      ...filters,
      filters,
   };
};
