import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../contexts/ToastContext";
import { useSosAxios } from "../../axios/useSosAxios";

export const useM_Options_CreateOption = (p?: TSoSConvoCreateNewThreadModalProps) => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Create new options is successful!",
            },
         });
         p && p.onSuccess();
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Create new options is failed!",
            },
         });
         p && p.onError();
      },

      mutationFn: (createBody: SoS_Options_CreateOption_Body_Dto) =>
         sosAxios.post(`/options`, { ...createBody }).then<SoS_Options_CreateOption_Response>(({ data }) => {
            return data;
         }),
   });
};

export type TSoSConvoCreateNewThreadModalProps = {
   onSuccess: () => void;
   onError: () => void;
};

export class SoS_Options_CreateOption_Response {
   message!: string;
}

export class SoS_Options_CreateOption_Body_Dto {
   value!: string;
   label!: string;
   moid!: string;
   color?: string;
   priority?: number;
}
