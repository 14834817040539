import classNames from "classnames/bind";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import styles from "./PViPropertyDetailsTabOverview.module.scss";
import { SoSDataPoint_Params } from "../../../components/SoSDataPoint/SoSDataPoint";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";
import {
   useSoSOptions_Properties_PropertyTypes,
   useSoSOptions_PVi_HousingAuthorities,
   useSoSOptions_Sources_Source,
} from "../../../hooks/queries/options/useQSoS_Options";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { useQPVi_Property } from "../../../hooks/property-viability/queries/useQPVi_Property";
import { useMDatabase_UpdateValue } from "../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { useMPVi_Property_DeleteUnit } from "../../../hooks/property-viability/mutates/units/useMPVi_Property_DeleteUnit";
import { useMPVi_Property_CreateUnit } from "../../../hooks/property-viability/mutates/units/useMPVi_Property_CreateUnit";
import { PViDataPoint } from "../../../components/PViDataPoint/PViDataPoint";
import { useSearchQueryParams_PVi } from "../../../hooks/react/useSearchQueryParams";
import {
   SoSUploaderContainer,
   SoSUploaderContainerFileType,
} from "../../../components/common/SoSUploaderContainer/SoSUploaderContainer";
import { useEffect, useState } from "react";
import { useMDatabase_UploadFiles } from "../../../hooks/database/mutations/useMDatabase_UploadFiles";
import { useMDatabase_RemoveFile } from "../../../hooks/database/mutations/useMDatabase_DeleteFile";
import { ESoS_PropertyStatus } from "@simplyhomes/utils";

const cx = classNames.bind(styles);
export const PViPropertyDetailsTabOverview = () => {
   const [pid] = useSearchQueryParams_PVi().pid;

   const qPVi_Property = useQPVi_Property();

   const { property: p, units = [] } = qPVi_Property.data || {};

   const { housing_authority_id, status } = p || {};

   const [propertyFiles, setPropertyFiles] = useState<SoSUploaderContainerFileType[]>([]);

   useEffect(() => {
      if (!p || !p.files) return;
      const filesTranformed = p.files;
      setPropertyFiles(filesTranformed);
   }, [p]);

   const qProps_PropertyTypes = useSoSOptions_Properties_PropertyTypes({ housing_authority_id });

   const { options: options_props_propertyTypes } = qProps_PropertyTypes?.data || {};

   const qSources_Source = useSoSOptions_Sources_Source();

   const { options: options_sources_source } = qSources_Source?.data || {};

   const mPVi_Prop_CreateUnit = useMPVi_Property_CreateUnit();

   const qPViHousingAuthorities = useSoSOptions_PVi_HousingAuthorities({ q: pid });

   const getHousingAuthorityOptions = qPViHousingAuthorities.data?.options || [];

   const housingAuthorityOptions = getHousingAuthorityOptions.map((option) => ({
      value: option.value,
      label: option.label,
   }));

   const db_P = dbcb("sos_properties", [{ column: "pid", id: p?.pid || "" }], () => qPVi_Property.refetch());

   const db_S = dbcb("sos_sources", [{ column: "sid", id: p?.source.sid || "" }], () => qPVi_Property.refetch());

   const mDb_P_UpdateValueDatabase = useMDatabase_UpdateValue(db_P("pgcalc_max_prices"));

   const mUploadFiles = useMDatabase_UploadFiles(db_P("files"));

   const mRemoveFile = useMDatabase_RemoveFile(db_P("files"));

   if (!p) return null;

   const handleUploadFiles = (files: FileList) => {
      const newFiles: SoSUploaderContainerFileType[] = Array.from(files).map((file) => ({
         fuid: 0,
         uploading: true,
         file_name: file.name,
      }));

      const statisFilesSelected = propertyFiles || [];

      statisFilesSelected.push(...newFiles);

      mUploadFiles.mutate({ files });

      setPropertyFiles([...statisFilesSelected]);
   };

   const handleRemoveFile = (fuid: number) => {
      const fileToRemove = propertyFiles.find((f) => f.fuid === fuid);

      if (!fileToRemove) return;

      fileToRemove.uploading = true;

      mRemoveFile.mutate({ fuid });

      setPropertyFiles(propertyFiles.filter((f) => f.fuid !== fileToRemove.fuid));
   };

   return (
      <div className={`${styles.pdOverview} _jr_pviPropertyDetails`}>
         <div className={`${styles.pdoLargeDP}`}>
            <LargeDataPoint
               title="Offer Price"
               type="bold-value"
               value={p.target_purchase_price_override || p.target_purchase_price}
               format="currency"
               database={db_P("target_purchase_price_override")}
            />

            <LargeDataPoint title="Asking Price" type="bold-value" value={p.target_purchase_price} format="currency" />
            <LargeDataPoint
               title="As-is Value"
               type="bold-value"
               value={p.attom_avm}
               format="currency"
               database={db_P("attom_avm")}
            />
            <LargeDataPoint
               title="Reno amount"
               type="bold-value"
               value={p.estimated_renovation_cost}
               format="currency"
               database={db_P("estimated_renovation_cost")}
            />
            <LargeDataPoint
               title="ARV"
               type="bold-value"
               value={p.attom_arv}
               format="currency"
               database={db_P("attom_arv")}
            />
            <LargeDataPoint title="Cap Rate" type="bold-value" value={p.cap_rate} format="percentage" />
            <LargeDataPoint title="ARV Uplift" type="bold-value" value={p.arv_uplift} format="percentage" />

            <LargeDataPoint
               type={"bold-value"}
               format="currency"
               value={p.max_price_arv_uplift}
               title="Max price (ARV uplift)"
               buttons={[
                  {
                     title: "refresh",
                     onClick: () => mDb_P_UpdateValueDatabase.mutate({ value: true }),
                     loading: !!mDb_P_UpdateValueDatabase.isMutating,
                  },
               ]}
            />

            <LargeDataPoint
               type={"bold-value"}
               format="currency"
               value={p.max_price_cap_rate}
               title="Max price (Cap rate)"
               buttons={[
                  {
                     title: "refresh",
                     onClick: () => mDb_P_UpdateValueDatabase.mutate({ value: true }),
                     loading: !!mDb_P_UpdateValueDatabase.isMutating,
                  },
               ]}
            />

            <LargeDataPoint title="All-in" type="bold-value" value={p.all_in} format="currency" />
         </div>
         <div className={`${styles.pdoSmallDP}`}>
            <SmallDataPoint
               affix="sqft"
               title="Square Feet"
               type="only-value"
               value={p.sqft}
               icon="sqft"
               database={db_P("sqft")}
            />
            <SmallDataPoint
               prefix="Built in"
               title="Year Built"
               type="only-value"
               value={p.year_built}
               icon="yearbuilt"
               database={db_P("year_built")}
            />
            <PViDataPoint
               title="Property Type"
               type="only-value"
               value={p.property_type}
               icon="propertyType"
               options={options_props_propertyTypes}
               database={db_P("property_type")}
            />
            <SmallDataPoint
               title="Source"
               type="only-value"
               value={p.source.source}
               icon="source"
               options={options_sources_source}
               database={db_S("source")}
            />
            <PViDataPoint
               title="Housing Authority"
               type="only-value"
               options={housingAuthorityOptions}
               value={p.housing_authority_name}
               icon="housing_authority"
               database={db_P("housing_authority_id")}
            />
            <PViDataPoint title="Neighborhood" type="only-value" value={p.neighborhood_name} icon="neighborhood" />
         </div>
         <div className={`${styles.pdoUnits}`}>
            {units.map((_, i) => (
               <UnitData unitIndex={i} key={i} />
            ))}
            <SHrSosButton
               type="tonal"
               loading={!!mPVi_Prop_CreateUnit.isMutating}
               buttonProps={{ onClick: () => mPVi_Prop_CreateUnit.mutate() }}
            >
               <span>+ Add a unit</span>
            </SHrSosButton>
         </div>

         {status !== ESoS_PropertyStatus.NEW && (
            <div className={cx("inspectionC")}>
               <span className={cx("title")}>
                  <b>File Upload</b>
               </span>
               <div className={cx("SOWC")}>
                  <div className={cx("headingC")}>
                     <span>
                        <b>Files</b>
                     </span>
                  </div>
                  <SoSUploaderContainer
                     type="files"
                     values={propertyFiles || []}
                     onUpload={(fs) => handleUploadFiles(fs)}
                     onRemove={handleRemoveFile}
                  >
                     <SHrSosButton
                        type="text"
                        buttonProps={{
                           className: styles.uploadFileBtn,
                           disabled: !!mUploadFiles.isMutating || !!mRemoveFile.isMutating,
                        }}
                     >
                        Upload a file
                     </SHrSosButton>
                  </SoSUploaderContainer>
               </div>
            </div>
         )}
      </div>
   );
};

const LargeDataPoint = (p: SoSDataPoint_Params) => {
   return (
      <div className={`${styles.largeDataPoint}`}>
         <PViDataPoint {...p} />
      </div>
   );
};
const SmallDataPoint = (p: SoSDataPoint_Params) => {
   return (
      <div className={`${styles.smallDataPoint}`}>
         <PViDataPoint {...p} />
      </div>
   );
};
const UnitData = ({ unitIndex }: { unitIndex: number }) => {
   const qPVi_Property = useQPVi_Property();

   const { units = [] } = qPVi_Property.data || {};

   const u = units[unitIndex];

   const mPVi_Prop_DeleteUnit = useMPVi_Property_DeleteUnit({ uid: u?.uid || 0 });

   if (!u) return null;

   const db_U = dbcb("sos_units", [{ column: "uid", id: u.uid }], () => qPVi_Property.refetch());

   return (
      <div className={`${styles.unitData}`}>
         {mPVi_Prop_DeleteUnit.isMutating ? (
            <SHrSpinner />
         ) : (
            units.length > 1 && (
               <SoSConfirmWrapper
                  title="Are you sure you want to remove this unit out this property?"
                  content="This action allows to remove this unit out this property."
                  type="default"
                  onCancel={() => {}}
                  onConfirm={() => mPVi_Prop_DeleteUnit.mutate()}
               >
                  <button title="remove" type="button">
                     x
                  </button>
               </SoSConfirmWrapper>
            )
         )}
         <b>Unit #{unitIndex + 1}</b>
         <SmallDataPoint
            title="Beds"
            type="only-value"
            value={u.bed}
            affix="beds"
            icon="bedroom"
            database={db_U("bed")}
         />
         <SmallDataPoint
            title="Baths"
            type="only-value"
            value={u.bath}
            affix="baths"
            icon="bathroom"
            database={db_U("bath")}
         />
         <SmallDataPoint
            title="Gross Rent"
            type="only-value"
            value={u.gross_rent_final}
            format="currency"
            prefix="Gross:"
         />
         <SmallDataPoint title="HCV Rent" type="only-value" value={u.hcv_rent_final} format="currency" prefix="HCV:" />
         <PViDataPoint
            title="Utilities"
            type="only-value"
            value={u.utilities_tenant_paying_final}
            format="currency"
            prefix="Utils Deduct:"
         />
      </div>
   );
};

// const Sources = () => {
//    return (
//       <div className={cx("detailSourcesC")}>
//          <div className={cx("sourceInforC")}>
//             <span>
//                <b>MLS</b>
//             </span>
//             <div className={cx("dot")}></div>
//             <PViDataPoint
//                title="Inspection Status"
//                auditKey="source_inspection-status"
//                type="only-value"
//                value="30 Sep 2023"
//                isMocked
//             />
//             <div className={cx("dot")}></div>
//             <PViDataPoint
//                title="Inspection Status"
//                auditKey="source_inspection-status"
//                type="only-value"
//                value="30 Sep 2023"
//                isMocked
//             />
//          </div>
//          <div className={cx("divider-container")}>
//             <hr className={cx("divider")} />
//          </div>
//          <div className={cx("sourceInforC")}>
//             <span>
//                <b>DTS</b>
//             </span>
//             <div className={cx("dot")}></div>
//             <PViDataPoint
//                title="Inspection Status"
//                auditKey="source_inspection-status"
//                type="only-value"
//                value="30 Sep 2023"
//                isMocked
//             />
//             <div className={cx("dot")}></div>
//             <PViDataPoint
//                title="Inspection Status"
//                auditKey="source_inspection-status"
//                type="only-value"
//                value="30 Sep 2023"
//                isMocked
//             />
//          </div>
//       </div>
//    );
// };
