import classNames from "classnames/bind";
import styles from "./OffersPropertyCard.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
import {
   TSoS_Offers_GetLeadById_Property,
   useQOffers_Lead,
} from "../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { useMOffers_Leads_RemoveProperty } from "../../../hooks/offers/mutates/leads/useMOffers_Leads_RemoveProperty";
import { useOffersContext } from "../../../contexts/OffersContext";
import { useSearchQueryParams_PVi } from "../../../hooks/react/useSearchQueryParams";
const cx = classNames.bind(styles);

export const OffersPropertyCard = ({ pid }: TOffersPropertyCardProps) => {
   const setPid = useSearchQueryParams_PVi().pid[1];

   const { offersState, offersDispatch } = useOffersContext();

   const { pid: selectedPid } = offersState;

   const qOffersLead = useQOffers_Lead();

   const mutateOffersRemoveProperty = useMOffers_Leads_RemoveProperty();

   if (!qOffersLead.data?.lead) return null;

   const { properties } = qOffersLead.data?.lead;

   const property = properties.find((p) => p.pid === pid) as TSoS_Offers_GetLeadById_Property;

   const handleRemovePropertyInLead = (pid: string) => {
      mutateOffersRemoveProperty.mutate({ pid });
   };

   const handleSelectedproperty = () => {
      offersDispatch({ overwrite: { pid: selectedPid === property.pid ? "" : property.pid } });
   };

   return (
      <div className={cx("container", { selected: selectedPid === property.pid })}>
         <div className={cx("propertyImgC")}>
            <div className={cx("image")}>
               <img src={property.street_view_url_150} alt="" />
            </div>
            <SHrSosButton type="outlined" buttonProps={{ className: cx("btn"), onClick: () => setPid(pid) }}>
               Open details
            </SHrSosButton>
         </div>
         <div className={cx("infoC")} onClick={() => handleSelectedproperty()}>
            <div className={cx("addressC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/offer/location_on_black.svg")} alt="" />
               </div>
               <span>{property.address_one_line}</span>

               {properties.length > 1 && (
                  <div>
                     <SoSConfirmWrapper
                        title="Are you sure you want to remove this property out this lead?"
                        content="This action allows to remove this property out this lead."
                        type="default"
                        onConfirm={() => handleRemovePropertyInLead(property.pid)}
                     >
                        <SHrSosButton
                           type="text"
                           buttonProps={{
                              className: cx("btn-delete"),
                           }}
                           loading={mutateOffersRemoveProperty.isPending}
                        >
                           <div className={cx("img-icon", "delete")}>
                              <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
                           </div>
                        </SHrSosButton>
                     </SoSConfirmWrapper>
                  </div>
               )}
            </div>
            <div className={cx("numbersC")}>
               <div className={cx("price")}>
                  <span>Asking Price</span>
                  <span className={cx("number-price")}>{SHu_js_currencyFormat(property.price)}</span>
               </div>
               <div className={cx("price")}>
                  <span>Current Max Price</span>
                  <span className={cx("number-price")}>
                     {property.max_price_arv_uplift ? SHu_js_currencyFormat(property.max_price_arv_uplift) : "N/A"}
                  </span>
               </div>
            </div>
            <div className={cx("numbersC")}>
               <div className={cx("price")}>
                  <span>Underwriting</span>
                  <span className={cx("number-price")}>Status</span>
               </div>
               <div className={cx("price")}>
                  <span>Inspection</span>
                  <span className={cx("number-price")}>Status</span>
               </div>
            </div>
         </div>
      </div>
   );
};

type TOffersPropertyCardProps = {
   pid: string;
   sid: number;
};
